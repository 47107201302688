
/*
 * Date: 2024
 * Description: Form content for new punch or edit event
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { ReactElement , ChangeEvent , useState, useEffect } from "react";
import { Box , Grid , Typography , TextField , MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// Interfaces && types
import { i_punch_form } from "../../../interfaces/timesheet.interface";

//Styles
import { input_base , bottom_line} from "../../../styles/form.styles";
import { formatTimeDifference } from "../../../utils/utility";

const PunchForm = ( props : i_punch_form ) : ReactElement => {
    const formatDay = (timestamp : number) => {
        if(!timestamp)  return '';
        const date = new Date(timestamp * 1000); 
        const pad = (n : any) => n < 10 ? `0${n}` : n;
        return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
    }
    const [ Etime , setEtime ] = useState<string>('')
    
    const F = props.form;
    const E = props.errors;
    const [ initial_data ] = useState<string>(formatDay(F.punch_in))
    const handleChange = ( event : ChangeEvent<HTMLInputElement> ) => {
        const { name, value } = event.target;
        var clean_value : any = value
        if(name === 'punch_in' || name === 'punch_out') {
            clean_value = toUnixTimestamp(value)
            if(name === 'punch_out'){
                if(clean_value < F.punch_in){
                    setEtime('End time must be after start time')
                    return
                }
            }else{
                if(clean_value > F.punch_out){
                    setEtime('Start time must be before end time')
                    return
                }
            }
        }
        props.setForm({...F, [name] : clean_value })
    }
    useEffect(() => {
        setEtime('')
    },[F])
    const formatDate = (timestamp : number) => {
        if(!timestamp)  return '';
        const date = new Date(timestamp * 1000); 
        const pad = (n : any) => n < 10 ? `0${n}` : n;
        return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
    }

    const toUnixTimestamp = (dateTimeString: string): number => {
        const date = new Date(dateTimeString);
        const unixTimestamp = Math.floor(date.getTime() / 1000);
        return unixTimestamp;
    }
    const findUser = () : string => {
        const user = props.users.find(user => user._id === F.user_id);
        if (user)  return user.first_name + ' ' + user.last_name;
        return '';
    }
    return (
        <Box component={"form"} p={2} m={2} sx={{ marginBottom : '2vh' , backgroundColor : props.mode === 'Dark' ? '#333' : 'white'}} id={'user_inputs'} >
            <Grid container>
                <Grid item xs={12}>
                    <Typography sx={{ fontWeight : 800, fontSize : 20, marginBottom : '2vh' , color : 'red'}}>{Etime}</Typography>
                </Grid>
                <Grid item xs={4} p={1}>
                   <Typography sx={{ fontSize : 20 }}>Selected user :</Typography>
                </Grid>
                <Grid item xs={8} p={1}>
                   <Typography sx={{ fontSize : 20}}>{findUser()}</Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={4} p={1}>
                    <Typography  sx={{ fontSize : 20 }}>Select a type : </Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField sx={[input_base , { width : '300px'} ]} name={'type'} size="small" value={F.type} error={E.type !== ""} helperText={E.type}
                        onChange={handleChange}autoComplete={props.dev ? "off" : "new-password" } select>
                        <MenuItem value="in"> In </MenuItem>
                        <MenuItem value="break"> Break </MenuItem>
                        <MenuItem value="lunch"> Lunch </MenuItem>
                    </TextField>           
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={4} p={1}>
                    <Typography sx={{ fontSize : 20 }}>Start time :</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField sx={[input_base , { width : '300px'} ]} type={'datetime-local'} name={'punch_in'} size="small" value={formatDate(F.punch_in)} error={E.punch_in!== ""} helperText={E.punch_in}
                        onChange={handleChange}autoComplete={props.dev? "off" : "new-password" }
                        inputProps={{
                            min: `${initial_data}T00:00`,
                            max: `${initial_data}T23:59`
                        }}/>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={4} p={1}>
                    <Typography sx={{ fontSize : 20 }}>End time :</Typography>
                </Grid>
                <Grid item xs={8} >
                    <TextField sx={[input_base , { width : '300px'} ]} type={'datetime-local'} name={'punch_out'} size="small" value={formatDate(F.punch_out)} error={E.punch_out!== ""} helperText={E.punch_out}
                        onChange={handleChange}autoComplete={props.dev? "off" : "new-password" }
                        inputProps={{
                            min: `${initial_data}T00:00`,
                            max: `${initial_data}T23:59`
                        }}/>
                </Grid>
                { F.punch_in !== 0 && F.punch_out !== 0 && 
                    <Grid item xs={12} p={1}>  
                        <Typography  sx={{ fontSize : 20 }}>Total elasped time : {formatTimeDifference(F.punch_in * 1000, F.punch_out * 1000)} </Typography>
                    </Grid>
                }
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={4} p={1}>
                    <Typography sx={{ fontSize : 20 }}>Notes :</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField sx={[input_base , { width : '300px'} ]} name={'notes'} size="small" value={F.notes} error={E.notes!== ""} helperText={E.notes}
                        onChange={handleChange}autoComplete={props.dev? "off" : "new-password" }/>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={5}></Grid>
                <Grid item xs={2} mr={2}>
                    <LoadingButton sx={{   border : '1px solid gray' , marginRight : '1vh'}} loading={false} fullWidth variant="contained" color="primary" onClick={() => props.callback()}>Submit</LoadingButton>
                </Grid>
                <Grid item xs={5}></Grid>
            </Grid>
        </Box>
    )
}

export default PunchForm