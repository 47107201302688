
/*
* Date: 2024
* Description: Constant reusable threw the app.
* Author: Philippe Leroux @ skitsc
*/

//Interfaces && types
import { i_error , i_promise , i_search_filter,} from "../interfaces/utility.interface";
import { i_user, i_user_errors } from "../interfaces/user.interface";
import { i_tbl_header } from "../interfaces/utility.interface";
import { header_row } from "../styles/tbl.styles";
import { i_user_type } from "../interfaces/utility.interface";
import { i_app_config, i_app_config_errors, i_mail_config, i_mail_config_errors } from "../interfaces/setting.interface";
import { i_timesheet , i_timesheet_complete, i_timesheet_errors , i_punch, i_punch_errors } from "../interfaces/timesheet.interface";

const default_error : i_error = {
	err: false,
	message: ""
}
const default_mail_config : i_mail_config = {
	_id: "",
    host : "",
    from : "",
    username : "",
    password : "",
    secure : false,
    pool : false,
	active : true
}
const mail_errors : i_mail_config_errors = {
	_id: "",
    host : "",
    from : "",
    username : "",
    password : "",
    secure : "",
    pool : "",
	active : ''
}
const empty_user: i_user = {
	_id: "",
	email: "",
	password: "",
	confirm_password: "",
	first_name: "",
	last_name: "",
	unit_number: '',
	door_number : '',
	street : '',
	city : '',
	state : 'QC',
	zip : '',
	phone_one: "",
	phone_two: "",
	type: "User",
	disabled : true,
	hour_rate: 0,
	skill_code: "Default",
	start : Date.now(),
    end : 0,
    nas : "",
	timestamp : 0,
	code : "",
    nip : '',
	confirm_nip : ''
};

const empty_user_errors : i_user_errors = {
	_id: "",
	email: "",
	password: "",
	confirm_password: "",
	first_name: "",
	last_name: "",
	unit_number: '',
	door_number : '',
	street : '',
	city : '',
	state : '',
	zip : '',
	phone_one: "",
	phone_two: "",
	type: "",
	disabled : "",
	hour_rate: "",
	skill_code: "",
	start : "",
    end : "",
    nas : "",
	timestamp : "",
	code : "",
	nip : ""
};


const empty_promise : i_promise = {
	data : [],
	message : "",
	type : "Failed"
}
const default_org : i_app_config = {
	name : '',
	phone : '',
	email : '',
	url : '',
	phone_two : '',
	street : '',
	city : '',
	state : '',
	zip : '',
	country : '',
	door_number : '',
	unit_number : '',
	path : '',
	id : '',
	_id : '',
	activity : '',
	primary_color : '#B71F26',
    secondary_color : '#5555',
    selected_color : '#B71F26',
    hover_color : '#444',
	timestamp : 0,

}
const org_errors : i_app_config_errors = {
	name : '',
	phone : '',
	email : '',
	url : '',
	phone_two : '',
	street : '',
	city : '',
	state : '',
	zip : '',
	country : '',
	door_number : '',
	unit_number : '',
	path : '',
	id : '',
	_id : '',
	activity : '',
	primary_color : '',
    secondary_color : '',
    selected_color : '',
    hover_color : '',
	timestamp : '',
}


const default_filter : i_search_filter = {
	page : 1,
	rows_per_page : 10,
	search : '',
	sort_by : 'timestamp',
	sort_order : 'DESC',
}

const default_punch : i_punch = {
	_id : "",
	user_id : "",
	punch_in: 0,
	punch_out: 0,
	type : 'in',
	timesheet_id : '',
	rate: 0,
	path : [],
	notes : '',
	timestamp: 0
}
const default_punch_errors : i_punch_errors = {
	user_id : "",
	punch_in: "",
	punch_out: "",
	type : '',
	timesheet_id : '',
	rate: "",
	notes : "",
	timestamp: 0
}
const user_headers : i_tbl_header[] = [
	{ value : 'Added date', css : header_row , portion : 1}, 
	{ value : 'Name', css : header_row , portion : 1},
	{ value : 'Email', css : header_row , portion : 1},
	{ value : 'Phone', css : header_row , portion : 1},
	{ value : 'Type', css : header_row , portion : 1},
	{ value : 'Action', css : header_row , portion : 1}
]
const users_type : i_user_type[] = [
	{ value : "Admin", allowed : true },
	{ value : "User" , allowed : true },
]
const allowed_extensions = ['jpg', 'jpeg', 'png', 'gif' , 'svg', 'pdf'];
const lexique = {
	postal_code_regex: /^[ABCEGHJKLMNPRSTVXY]\d[A-Z] \d[A-Z]\d$/,
	zip_regex: /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/i,
	Name_regex: /^(?=[a-zA-Z0-9._]{3,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
	str_regex: /^[^\\/&[\]{}<>]*$/,
	str_regex_min: /^[^\\/&@.,(){}<>]{5,}$/,
	password_two_regex: /^[a-zA-Z0-9,.:;]{5,}$/,
	password_regex:/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/,
	email_regex:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	phone_regex: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
	mobile_phone_regex:/^(?:\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*)?$/,
	alpha_regex: /^[A-Za-z0-9]+$/i,
	name_regex: /^[A-Za-z\s]+$/i,
	address_regex: /^\s*\S+(?:\s+\S+){1,10}/,
	normalText_regex: /^[A-Za-z0-9\s]+$/,
	websiteurl_regex:/^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(\/[^\s]*)?$/,
	cap_number_regex : /^(?=.*[A-Z])(?=.*\d).+/,
	one_special_regex : /^(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d]).*$/,
	urlRegex : /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
	hexColorRegex : /^#([0-9a-fA-F]{3}){1,2}$/,
	nas_regex: /^(\d{3}-\d{3}-\d{3})|(\d{9})$/,
	nip_regex: /^\d+$/,
};
const lexique_message = {
	empty_error: "Please fill in the field.",
	invalid_type_error: "Please provide a valid type.",
	select_error : "Please select an option.",
	str_error: "Please do not use special characters like []|{}<>",
	zip_error: "Please enter a valid canadian postal code.",
	alpha_error: "Please use only alphanumeric characters.",
	phone_error: "Please provide a valid phone number, e.g., 1-514-111-1111.",
	special_error: "Special characters are not allowed.",
	email_error: "The email must match the email format, e.g., example@example.com.",
	numeric_error: "only accepts numerical values, e.g., 0, 12.",
	password_error: "must be between 8 and 20 characters long, including at least one lowercase letter, one uppercase letter, one digit, and one special character!",
	file_error : "Please provide valid files.",
	match_error : "Passwords do not match.",
	assets_error : "Please provide valid assets.",
	date_error : "Please provide a valid date",
	tax_rates_error: "Please provide a valid tax rates",
	tax_type_error: "Please provide a valid tax type",
	currency_error : "Please provide a valid currency",
	payment_terms_error : "Please provide a valid payment terms",
	portal_language_error : "Please provide a valid portal language",
	customer_type_error : "Please provide a valid customer type",
	customer_title_error : "Please provide a valid customer title",
	string_arr_error : "Please provide a valid string array",
	contact_arr_error : "Please provide a valid contact array",
	url_error : "Please provide a valid url, e.g., http://www.example.com.",
	color_error : "Please provide a valid color, e.g., #0000FF",
	nas_error : "Please provide a valid NAS number, e.g., 000-000-000",
	user_type_error : "Please provide a valid user type",
	skill_code_error : "Please provide a valid skill code",
	nip_error : 'Please provide a valid nip'
};
const colors = [{ color : 1, label : 'red' }, { color : 2, label : 'blue' }, { color : 3, label : 'purple' },{ color : 4, label : 'yellow' },{ color : 5, label : 'green' }, { color : 6, label : 'orange' }]
const provinces = [{ value : "QC"} , { value : "MB"} , { value : "ON" } , { value : " BC"} , { value : "NB"},{ value : "AB"} , { value : "LB"}]
const country = [{ value : "Canada" }, { value : "United States" }]
const years = [ { value : "2000" } , { value : "2001" } , { value : "2002" } , { value : "2003" } , { value : "2004" }, { value : "2005" } , { value : "2006" } , { value : "2007" }, { value : "2008" } ,
{ value : "2009" }, { value : "2010" } , { value : "2011" }, { value : "2012" } , { value : "2013" }, { value : "2014" } , { value : "2015" }, { value : "2016" } , { value : "2017" }, { value : "2018" } , { value : "2019" } , 
{ value : "2020" } , { value : "2021" }, { value : "2022" } , { value : "2023" }, { value : "2024" }]



const default_timesheet : i_timesheet = {
    _id : "",
    user_id : "",
    sum : 0,
    pay_period : 0,
    assets : [],
    interval : 0,
    timestamp : 0,
}
const default_timesheet_complete : i_timesheet_complete = {
    _id : "",
    user : empty_user,
	user_id : '',
	punches :[],
    sum : 0,
    pay_period : 0,
    assets : [],
    interval : 0,
    timestamp : 0,
}
const default_timesheet_errors : i_timesheet_errors = {
	_id: "",
    user_id: "",
    week_one : "",
    week_two : "",
    sum : "",
    pay_period : "",
    assets : "",
    interval : "",
}

const pay_periods = [ 0, 1, 2 ,3 , 4 , 5 , 6 , 7 , 8 , 9 , 10 , 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,  21, 22, 23, 24, 25 , 26, 27, 28, 29, 30 , 31, 32, 33, 34, 35 , 36, 37, 38, 39, 40,  41, 42, 43, 44, 45 ,  46, 47, 48, 49, 50 ,  51]
export { lexique , default_timesheet, default_punch, default_punch_errors ,default_timesheet_errors, pay_periods, default_timesheet_complete, org_errors, empty_user_errors , default_org, lexique_message , empty_user , default_error , users_type, colors, default_filter, years,country,
	 empty_promise , allowed_extensions ,user_headers , provinces , mail_errors , default_mail_config }