/*
 * Date: 2023
 * Description: Help page
 * Author: Philippe Leroux @ skitsc
 */

import { Box, Typography } from "@mui/material"
import { ReactElement } from "react"

//Interfaces && types
import { i_initial_props } from "../interfaces/utility.interface"
import Footer from "../components/utility/footer"

const Help = ( props : i_initial_props ) : ReactElement => {
    return (
        <Box>
            <Box sx={{ minHeight : '91vh'}}>
                <Box sx={{ display : 'flex' , justifyContent : 'center' , paddingTop : '5vh' ,  marginLeft : '5%' , marginRight : '5%' }}>
                    <Box sx={{ border : '1px solid gray' , padding : '10px', maxWidth : '500px'}}>
                        <Typography sx={{ textAlign : 'center'}}>Please report any questions au issues to pleroux@skitsc.com</Typography>
                    </Box>
                </Box>
            </Box>
            <Footer type={'center'} {...props}/>
        </Box>
    )
}


export default Help