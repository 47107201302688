/*
 * Date: 2024
 * Description: Fetch api logics..
 * Author: Philippe Leroux @ skitsc
 */

//Utilities functions
import { f_empty_promise } from "../utils/utility";

//Interfaces and types
import { t_method , t_credentials } from "../types/types";
import { i_promise } from "../interfaces/utility.interface";

const api_url = process.env.REACT_APP_API_URL

const f_compose_headers = ( method : 'POST' | 'DELETE' | 'GET' | 'PUT' | 'PATCH', creds : t_credentials , body : any ) => {
  var options;
  var head : HeadersInit = {}
  if(creds === 'include'){
    head = new Headers ({
      "accept": "application/json",
      "content-type": "application/json",
      "credentials": "include",
    })
    options = {
      method: method,
      credentials : creds,
      headers: head,
      body : body == null ? null : JSON.stringify(body)
    }
  }else{
      head = new Headers({
          "accept": "application/json",
          "content-type": "application/json",
      })
      options = {
          method: method,
          headers: head,
          credentials : undefined,
          body : body == null ? null : JSON.stringify(body)
      }
  }
  return options;
}
const f_fetch = async (url : string , method : t_method , cred : boolean, body : any ) : Promise<i_promise> => {
    const empty_promise : i_promise = f_empty_promise();
    var creds : t_credentials = 'include'
    if(cred) creds = 'include'
    else creds = 'omit'
    const headers = f_compose_headers( method , creds , body) 
    try{
        const rep = await fetch(api_url + '/api/web' + url, headers)
        .then((response) => {
            return response.json() })
        .then((response) => {
            return response })
        .catch(() => {
            empty_promise.type = 'Failed'
            empty_promise.message = 'Unable to fetch api , contact with administrator'
            return empty_promise
        })
        return rep
    }catch(err){
        empty_promise.message = 'Bad request'
        empty_promise.type = 'Bad request'
        return empty_promise
    }
}
const f_fetch_multiform = async ( url : string , Method : "POST" | "PUT"  | 'PATCH' , body : FormData) => {
  const empty_promise : i_promise = f_empty_promise();
  const headers = f_generate_form_data_header(body , Method , 'include')
  try{
      const rep = await fetch(api_url + '/api/web' + url, headers)
      .then((response) => {
          return response.json()})
      .then((response) => {
          return response })
      .catch((err) => {
          console.log(err)
          empty_promise.type = 'Failed'
          empty_promise.message = 'Api error contact an admin'
          return empty_promise
      })
      return rep
  }catch(err){
      return null
  }
}
const f_generate_form_data_header = ( data : FormData , method : 'POST' | 'PUT' | 'PATCH' , creds : t_credentials ) => {
  var head;
  var options;
  head = new Headers ({
      Accept: "application/x-www-form-urlencoded",
      "credentials": "include",
    })
    options = {
      method: method,
      credentials : creds,
      headers: head,
      body : data
    }
  return options
}


const f_logout = async () => {
  const empty_promise = f_empty_promise();
  try{
      const output = await fetch(
        api_url +
        "/api/web/logout", {
          method: 'GET',
          credentials : 'include',
          redirect: "follow",
          headers: { 
              "Accept": "application/json",
              "Content-Type": "application/json",
              "credentials" : 'include'
          },
      })
      .then((res) => { return res.json() })
      .then(( res ) => { return res })
      .catch(err => {
          console.log(err)
          empty_promise.type = 'Error'
          empty_promise.message = 'Api error'
          return empty_promise
      });
      return output
  }catch(err){
      console.log(err)
      return empty_promise
  }
}
const takePhotoFromCamera = async(): Promise<Blob | null> => {
  if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.log('getUserMedia not supported');
      return null; // Return null or handle error condition
  }
  try {
      const constraints = { video: true };
      const stream: MediaStream = await navigator.mediaDevices.getUserMedia(constraints);
      const video: HTMLVideoElement = document.createElement('video');
      video.srcObject = stream;
      video.play();
      const canvas: HTMLCanvasElement = document.createElement('canvas');
      const context: CanvasRenderingContext2D | null = canvas.getContext('2d');
      const photoBlob: Blob | null = await new Promise((resolve, reject) => {
      video.addEventListener('loadedmetadata', () => {
              if (context) {
                  canvas.width = video.videoWidth;
                  canvas.height = video.videoHeight;
                  context.drawImage(video, 0, 0, canvas.width, canvas.height);
                  canvas.toBlob(resolve, 'image/png');
              } else {
                  reject(new Error('Could not get canvas context.'));
              }
          });
  
          video.addEventListener('error', (err) => {
              console.error('Video playback error:', err);
              reject(err);
          });
      });
      stream.getTracks().forEach(track => track.stop()); // Stop all tracks in the stream
      return photoBlob;
  } catch (err) {
      console.error("Error accessing camera: ", err);
      return null; 
  }
};
export {
  f_logout,
  f_fetch,
  f_fetch_multiform,
  takePhotoFromCamera
};
