/*
 * Date: 2024
 * Description: Reusable Footer can be formated on a specific way
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { Typography , Box , Grid} from "@mui/material"
import { Link } from "react-router-dom";
//Interfaces
import { i_footer_props } from "../../interfaces/utility.interface";

const Footer = (props : i_footer_props) => {
    const getCurrentYear = ()  => {
        const currentDate = new Date();
        return currentDate.getFullYear();
    }
    const footer_new = {
        bottom: 0,
        padding : '1vh' , borderTop : '1px solid #D3D3D3' 
    }
    return (
        <Box sx={footer_new }>
            <Grid container sx={{ width : '100%'}}>
                <Grid item xs={ props.type === 'right' ? 5 : 0}></Grid>
                    <Grid item xs={props.type === 'right' ? 7 : 12} sx={{ display : 'flex'}}>
                        {props.mobile ?
                            <Grid container >
                                <Grid item xs={12}>
                                    <Box sx={{ display : 'flex' , justifyContent : 'center'}}>
                                        <Typography variant="body2" component={Link} to={'/help'} sx={{ textAlign : 'center' , marginRight : '8px'}}>Help</Typography>
                                        <Typography variant="body2"  component={Link} to={'/support'} sx={{ textAlign : 'center', marginRight : '8px'}}>Support</Typography>

                                    </Box>
                                </Grid>
                                <Grid item xs={12} mt={1}>
                                    <Box sx={{ display : 'flex' , justifyContent : 'center'}}>

                                        <Typography variant="body2" component={Link} to={'/terms'} sx={{ textAlign : 'center', marginRight : '8px' , cursor : 'pointer' }}>Terms</Typography>
                                        <Typography variant="body2" component={Link} to={'/privacy'} sx={{ textAlign : 'center', marginRight : '8px' , cursor : 'pointer' }}>Privacy</Typography>
                                        <Typography variant="body2" component={Link} to={'/cookies'}sx={{ textAlign : 'center', marginRight : '8px' , cursor : 'pointer' }}>Cookies</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} mt={1}>
                                    <Box sx={{ display : 'flex' , justifyContent : 'center' }}>
                                        <Typography variant="body2" sx={{ textAlign : 'center' , marginRight : '6px'}}>{"© " + getCurrentYear() + " Xykal"}</Typography>
                                        <Typography variant="body2" sx={{ textAlign : 'center', marginRight : '6px'}}>All rights reserved.</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            :
                            <Grid container >
                            <Grid item xs={6}>
                                <Box sx={{ display : 'flex' , justifyContent : 'flex-start' }}>
                                    <Typography variant="body2" sx={{ textAlign : 'center' , marginRight : '6px'}}>{"© " + getCurrentYear() + " Xykal"}</Typography>
                                    <Typography variant="body2" sx={{ textAlign : 'center', marginRight : '6px'}}>All rights reserved.</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display : 'flex' , justifyContent : 'flex-end'}}>
                                    <Typography variant="body2" component={Link} to={'/help'} sx={{ textAlign : 'center' , marginRight : '8px' }}>Help</Typography>
                                    <Typography variant="body2" component={Link} to={'/support'} sx={{ textAlign : 'center', marginRight : '8px'}}>Support</Typography>
                                    <Typography variant="body2" component={Link} to={'/terms'} sx={{ textAlign : 'center', marginRight : '8px' , cursor : 'pointer' }}>Terms</Typography>
                                    <Typography variant="body2" component={Link} to={'/privacy'} sx={{ textAlign : 'center', marginRight : '8px' , cursor : 'pointer' }}>Privacy</Typography>
                                    <Typography variant="body2" component={Link} to={'/cookies'} sx={{ textAlign : 'center', marginRight : '8px' , cursor : 'pointer' }}>Cookies</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        }
                    </Grid>
            </Grid>
        </Box>
    )
}

export default Footer