
/*
 * Date: 2024
 * Description: Form content for user creation && update
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { ChangeEvent, ReactElement , useState } from "react";
import { Box , Grid, TextField , Typography , MenuItem , IconButton , Switch } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';

//Interfaces && types
import { i_user_form , i_user } from "../../../interfaces/user.interface";
import { i_user_type } from "../../../interfaces/utility.interface";

//Styles
import { sub_title , bottom_line } from "../../../styles/form.styles";
import { input_base } from "../../../styles/main.styles";

//Const
import { users_type , provinces} from "../../../utils/constant";
import { countDigits , formatSIN } from "../../../utils/utility";

//Icons
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const UserInputs = ( props : i_user_form ) : ReactElement => {
    const F = props.form
    const E = props.errors
    const [ leaved , setLeaved ] = useState<boolean>(F.end > 0 ? true : false)
    const handleDate = (date : Dayjs | null , type : string) => {
        if(date !== null){
            var updatedUser: i_user;
            updatedUser = {
                ...props.form,
                [type]:  Math.floor(date.valueOf()),
            };
            props.setForm(updatedUser);
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        let updatedUser: i_user = { ...props.form }; 
        if (name === 'nas') {
            const sin_count = countDigits(value);
            if (sin_count <= 9 ) {
                const formattedSIN = formatSIN(value);
                updatedUser = {
                    ...updatedUser,
                    [name]: formattedSIN
                };
            } 
        } else {
            updatedUser = {
                ...updatedUser,
                [name]: value
            };
        }
        props.setForm(updatedUser);
    };
    
    const handleLeaved = (e: ChangeEvent<HTMLInputElement>) => {
        var checked = e.target.checked
        setLeaved(checked)
        if(checked){
            props.setForm({
                ...props.form,
                end: Date.now()
            })
        }else{
            props.setForm({
               ...props.form,
                end: 0
            })
        }
    }
    const [ visible, setVisible ] = useState<boolean>( false )
    const [ visible_two , setVisibleTwo ] = useState<boolean>( false )
    const handleNip = ( event : ChangeEvent<HTMLInputElement> ) => {
        const { value } = event.target
        if (/^\d*$/.test(value) || value === '0') props.setForm({ ...props.form, nip: value });
    }
    return ( 
    <Box component={"form"} p={2} m={2} sx={{ marginBottom : '2vh' , backgroundColor : props.mode === 'Dark' ? '#333' : 'white'}} id={'user_inputs'} >
         <Grid container>
            <Grid item xs={12}>
                <Typography sx={{ fontWeight : 800 , fontSize : 20 , marginBottom : '2vh'}}>User personal details</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>First name* / Last name*</Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField sx={[input_base , { width : '300px'} ]} name={'first_name'} size="small" value={F.first_name} error={E.first_name !== ""} helperText={E.first_name}
                onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(event)}autoComplete={props.dev ? "off" : "new-password" }/>           
                <TextField sx={[input_base , { width : '300px'} ]} name={'last_name'} size="small" value={F.last_name} error={E.last_name !== ''} helperText={E.last_name}
                onChange={( event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> ) => handleChange(event)}autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Email address* / NAS number </Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField type={'email'} sx={[input_base , { width : '300px'} ]} name={'email'} size="small" value={F.email} error={E.email!== ''} helperText={E.email}
                onChange={( event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> ) => handleChange(event)}autoComplete={props.dev ? "off" : "new-password" }/>
                <TextField type={'text'} sx={[input_base , { width : '300px'} ]} name={'nas'} size="small" value={F.nas} error={false} helperText={""}
                onChange={( event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> ) => handleChange(event)}autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            <Grid item xs={3} >
                    <Typography sx={[ sub_title , { fontSize : 14}]}>Unit number / Civic number* / Street*</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base ,{ width : '125px' } ]} name={'unit_number'} size="small" value={F.unit_number} onChange={(e : ChangeEvent<HTMLInputElement>) => handleChange(e)}
                    helperText={E.unit_number} error={E.unit_number !== ''} autoComplete={props.dev ? "off" : "new-password" }/>
                     <TextField sx={[input_base ,{ width : '125px' } ]} name={'door_number'} size="small" value={F.door_number} onChange={(e : ChangeEvent<HTMLInputElement>) => handleChange(e)}
                    helperText={E.door_number} error={E.door_number !== ''}autoComplete={props.dev ? "off" : "new-password" }/>
                     <TextField sx={[input_base ,{ width : '350px' } ]} name={'street'} size="small" value={F.street} onChange={(e : ChangeEvent<HTMLInputElement>) => handleChange(e)}
                    helperText={E.street} error={E.street !== ''}autoComplete={props.dev ? "off" : "new-password" }/>
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={[ sub_title , { fontSize : 14}]}> City* / Province* / Postal code*</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base ,{ width : '240px' } ]} name={'city'} size="small" value={F.city} onChange={(e : ChangeEvent<HTMLInputElement>) => handleChange(e)}
                    helperText={E.city} error={E.city!== ''}autoComplete={props.dev ? "off" : "new-password" }/> 
                    <TextField sx={[input_base ,{ width : '240px' } ]} name={'state'} value={F.state} size="small" onChange={(e : ChangeEvent<HTMLInputElement>) => handleChange(e)}
                    helperText={E.state} error={E.state!== ''}autoComplete={props.dev ? "off" : "new-password" } select> 
                      {provinces.map((provinces , index ) => (
                            <MenuItem key={index} value={provinces.value}>{provinces.value}</MenuItem>
                        ))}
                    </TextField>
                    <TextField sx={[input_base ,{ width : '120px' } ]} name={"zip"} size="small" inputProps={{ maxLength: 7 }} value={F.zip} onChange={(e : ChangeEvent<HTMLInputElement>) => handleChange(e)}
                    helperText={E.zip} error={E.zip!== ''}autoComplete={props.dev ? "off" : "new-password" }/>
                </Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Phone number* / Aditionnal phone </Typography>
            </Grid>  
            <Grid item xs={9}>
                <TextField sx={[input_base , { width : '300px'} ]} type={'tel'} size="small" name={'phone_one'} value={F.phone_one} onChange={( event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> ) => handleChange(event)}
                    error={E.phone_one !== ''} helperText={E.phone_one}autoComplete={props.dev ? "off" : "new-password" }/>
                     <TextField sx={[input_base , { width : '300px'} ]} size="small" type={'tel'} value={F.phone_two} name={'phone_two'} onChange={( event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(event)}
                error={E.phone_two !== ''} helperText={E.phone_two}autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>

            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>User role *</Typography>
            </Grid>
            <Grid item xs={9}>
            <TextField  sx={[input_base , { width : '300px'} ]} name={'type'} size="small" select  value={F.type}
                onChange={( e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> ) => handleChange(e)}
                error={E.type !== '' ? true : false} helperText={E.type}>
                    { users_type.map((type : i_user_type , index : number) => (
                        <MenuItem key={index} disabled={!type.allowed} value={type.value}>{type.value}</MenuItem>
                    ))}
            </TextField>
            </Grid>            
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            {F.type === 'Admin' ?
            <>

            <Grid item xs={3}>
                <Typography sx={sub_title}>Password* / Confirm password*</Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField sx={[input_base , { width : '300px'} ]} size="small" name={'password'} placeholder={"Password"} value={F.password} type={visible ? "text" : "password"}
                    onChange={( event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> ) => handleChange(event)}
                    error={E.password!== '' ? true : false} helperText={E.password}
                    InputProps={{ autoComplete : 'off' , endAdornment: <IconButton edge="end" aria-label="toggle password visibility" 
                    onClick={() => setVisible(!visible)}>{visible ? <VisibilityOffIcon /> : <VisibilityIcon />}</IconButton> } }
                    />
                <TextField sx={[input_base , { width : '300px'} ]} size="small" name={'confirm_password'} placeholder={"Confirm password"} value={F.confirm_password} type={visible_two ? "text" : "password"}
                onChange={( event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(event)}
                error={E.confirm_password !== '' ? true : false} helperText={E.confirm_password}
                InputProps={{ autoComplete : 'off' , endAdornment: <IconButton edge="end" aria-label="toggle password visibility" 
                onClick={() => setVisibleTwo(!visible_two)}>{visible_two ? <VisibilityOffIcon /> : <VisibilityIcon />}</IconButton> }}/>
            </Grid>
            </>
            :
            <>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Employe code */ Nip *</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base , { width : '150px'} ]} autoComplete={props.dev ? "off" : "new-password" }  onChange={( event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(event)} error={E.code !== ''} placeholder={"Employe code for punch"}
                    helperText={E.code} value={F.code} name={'code'} size="small"/>
                    <TextField sx={[input_base , { width : '150px'} ]} autoComplete={props.dev ? "off" : "new-password" } onChange={handleNip} error={E.nip !== ''} placeholder={"Employe nip for punch"} 
                    helperText={E.nip} value={F.nip} name={'nip'} size="small"/>
                </Grid>
            </>
            }
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Hour rates *</Typography>
            </Grid>
            <Grid item xs={9}>

                <TextField sx={[input_base , { width : '200px'} ]} name={'hour_rate'} size="small" type='number' value={F.hour_rate} onChange={( event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(event)}
                error={E.hour_rate !== ''} helperText={E.hour_rate}autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            <Grid item xs={3} mt={2}>
                    <Typography sx={sub_title}>Beginning date*</Typography>
                </Grid>
                <Grid item xs={9} mt={1}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker sx={[input_base , { width : '300px'}]} label="" value={dayjs(F.start)} onChange={(newValue : Dayjs | null) => handleDate(newValue , 'start')}/>
                        </LocalizationProvider>
                </Grid>
                <Grid item xs={3} mt={2}>
                    <Typography sx={sub_title}> Laid off*  { leaved && "/ Employement end date "}</Typography>
                </Grid>
                <Grid item xs={9} mt={1}>
                    <Switch checked={leaved} sx={[input_base , { width : '300px'}]} name={'leaved'} onChange={(e) => handleLeaved(e)}></Switch>
                    {leaved &&
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker sx={[input_base , { width : '300px'}]} label="" value={dayjs(F.end)} minDate={leaved && dayjs(F.start)} onChange={(newValue : Dayjs | null) => handleDate(newValue , 'end')}/>
                        </LocalizationProvider>
                    }
                </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={1} mr={2}>
             <LoadingButton sx={{   border : '1px solid gray' , marginRight : '1vh'}} loading={false} fullWidth variant="contained" color="primary" onClick={() =>props.callback()}> Submit </LoadingButton>
            </Grid>
            <Grid item xs={1}>
             <LoadingButton sx={{ fontWeight : 700 ,backgroundColor : 'white' , color : 'black' , border : '1px solid gray'}} loading={false} fullWidth variant="contained" color="primary" onClick={() => props.handleClose('table')}> Cancel </LoadingButton>
            </Grid>
            <Grid item xs={5}></Grid>
        </Grid>
        </Box>
    
    )
}

export default UserInputs;