/*
 * Date: 2023
 * Description: Privacy page
 * Author: Philippe Leroux @ skitsc
 */

import { Box, Typography } from "@mui/material"
import { ReactElement } from "react"

//Interfaces && types
import { i_initial_props } from "../interfaces/utility.interface"
import Footer from "../components/utility/footer"

const Privacy = ( props : i_initial_props ) : ReactElement => {
    return (
        <Box>
            <Box sx={{ minHeight : '91vh'}}>
                <Box sx={{ display : 'flex' , justifyContent : 'center' , paddingTop : '5vh' ,  marginLeft : '5%' , marginRight : '5%' }}>
                    <Box sx={{ border: '1px solid gray', padding: '10px', maxWidth: '800px' , paddingBottom : '2vh' }}>
                        <Typography sx={{ fontWeight: 800, textAlign: 'center', fontSize: 22 }}>Privacy Policy</Typography>
                        
                        <Typography sx={{ fontWeight: 800, fontSize: 20 }}>Introduction</Typography>
                        <Typography>Welcome to Clutch punc. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our web application.</Typography>
                        
                        <Typography sx={{ fontWeight: 800, fontSize: 20 }}>2. Information We Collect</Typography>
                        <Typography sx={{ fontWeight: 800, fontSize: 18 }}>a. Personal Information</Typography>
                        <Typography>Account Registration: When you register for an account, we collect personal information such as your name, email address, and any other details required to create your account.</Typography>
                        
                        <Typography sx={{ fontWeight: 800, fontSize: 18 }}>b. Automatically Collected Information</Typography>
                        <Typography>Authentication Data: When you log in and use our app, we collect information related to your authentication, including a JSON Web Token (JWT) stored in a cookie.</Typography>
                        <Typography>Usage Data: We automatically collect information about your interactions with our app, such as the times you punch in and out.</Typography>
                        
                        <Typography sx={{ fontWeight: 800, fontSize: 20 }}>3. Use of Information</Typography>
                        <Typography sx={{ fontWeight: 800, fontSize: 18 }}>a. Personal Information</Typography>
                        <Typography>Account Management: We use your personal information to create and manage your account, authenticate your identity, and provide you with access to our app.</Typography>
                        <Typography>Communication: We may use your email address to send you important updates, notifications, and other information related to your use of the app.</Typography>
                        
                        <Typography sx={{ fontWeight: 800, fontSize: 18 }}>b. Automatically Collected Information</Typography>
                        <Typography>Authentication and Security: The JWT stored in a cookie (authToken) is used to authenticate your session and ensure the security of your interactions with the app.</Typography>
                        <Typography>App Functionality: We use usage data to improve the functionality and user experience of our app.</Typography>
                        
                        <Typography sx={{ fontWeight: 800, fontSize: 20 }}>4. Disclosure of Information</Typography>
                        <Typography>We do not sell, trade, or otherwise transfer your personal information to outside parties, except as described below:</Typography>
                        <Typography>Service Providers: We may share your information with third-party service providers who assist us in operating our app and providing services to you. These providers are required to protect your information and use it only for the purposes for which it was disclosed.</Typography>
                        <Typography>Legal Requirements: We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).</Typography>
                        
                        <Typography sx={{ fontWeight: 800, fontSize: 20 }}>5. Data Security</Typography>
                        <Typography>We implement a variety of security measures to maintain the safety of your personal information. The JWT used for authentication is securely signed and transmitted over HTTPS to prevent unauthorized access.</Typography>
                        
                        <Typography sx={{ fontWeight: 800, fontSize: 20 }}>6. Data Retention</Typography>
                        <Typography>We retain your personal information for as long as your account is active or as needed to provide you with our services. We will also retain and use your information as necessary to comply with legal obligations, resolve disputes, and enforce our agreements.</Typography>
                        
                        <Typography sx={{ fontWeight: 800, fontSize: 20 }}>7. Your Rights</Typography>
                        <Typography>You have the right to access, correct, or delete your personal information. You can manage your account settings or contact us at samyk@skitsc.com to exercise these rights.</Typography>
                        
                        <Typography sx={{ fontWeight: 800, fontSize: 20 }}>8. Changes to This Privacy Policy</Typography>
                        <Typography>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Last Updated" date at the top will be revised accordingly. Your continued use of the app after any changes signifies your acceptance of the updated Privacy Policy.</Typography>
                        
                        <Typography sx={{ fontWeight: 800, fontSize: 20 }}>9. Contact Us</Typography>
                        <Typography>If you have any questions about this Privacy Policy or our data practices, please contact us at samyk@skitsc.com.</Typography>
                    </Box>
                </Box>
            </Box>
            <Footer type={'center'} {...props}/>
        </Box>
    )
}


export default Privacy