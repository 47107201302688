


/*
 * Date: 2024
 * Description: Logs page logics
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { ReactElement , useState , useEffect , useContext } from "react";
import { Box , Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

//Interfaces
import { i_initial_props, i_logs , i_search_filter, i_socket_response , i_pagination_new , i_snack_alert , i_tbl_header , i_table , i_alert_props , i_top_tbl_bar , i_log_search_props } from "../../interfaces/utility.interface";

//Utilities
import { f_fetch } from "../../api/fetch";
import { f_encode_query_data } from "../../utils/utility";

//Components
import Footer from "../../components/utility/footer";
import CircularUnderLoad from "../../components/utility/center.loader";
import AlertDialog from "../../components/utility/alert";
import Tbl from "../../components/table/table";
import TopTableBar from "../../components/utility/top.table.bar";
import LogsFilter from "../../components/utility/inputs/logs.filter";

//Contexts
import { SocketContext } from "../../context/socket.context";
import { MainContext } from "../../context/context";

//Constants
import { default_filter , empty_promise } from "../../utils/constant";

//Styles
import { header_row , tbl_boxing } from "../../styles/tbl.styles";

const Logs = ( props : i_initial_props ) : ReactElement => {

    const socket = useContext(SocketContext)
    const { HandleLogout } = useContext(MainContext);
    const [ data , setData ] = useState<i_logs[]>([])
    const [ count, setCount ] = useState<number>(0)
    const [ loading, setLoading ] = useState<boolean>(false)
    const [ filter , setFilter ]  = useState<i_search_filter>({...default_filter , search : 'Any' })
    const [ api_error , setApiError ] = useState<i_snack_alert>({open : false , promise : empty_promise});
    const nav = useNavigate()
  //Sockets events
  useEffect(() => {
    socket.removeAllListeners("logs");
    socket.on('logs', ( output : i_socket_response ) => {
        if(output.type === 'Update'){
            const updateItem = ( ItemUpdated : i_logs ) => {
                const data_to_update = [ ...data ] 
                const updatedItems = data_to_update.map( ( item : i_logs , i : number) => {
                if (item._id === ItemUpdated._id) {
                    return ItemUpdated
                } else {
                return item
                }
                })
                setData(updatedItems)
            } 
            updateItem(output.item)
        }
        if(output.type === 'Add'){
            const addRow = ( ItemAdded : i_logs ) => {
                const data_to_update = [ ...data ]
                const objectExists = data_to_update.some(( obj : i_logs ) => obj._id === ItemAdded._id);
                if(!objectExists){
                    data_to_update.push(ItemAdded)
                    setData(data_to_update)    
                }
            } 
            addRow(output.item)
        }
    })
  },[socket , data])
    useEffect(() => {
        const getLogs = async() => {
            setLoading(true)
            const params = f_encode_query_data(filter)
            const res = await f_fetch('/logs/filtered?'+params , 'GET' , true , null)
            if(res.type === 'Success'){
                setData(res.data.logs)
                setCount(res.data.count)
            }else{
                if(res.type === 'Unauthorized') HandleLogout(nav)
            }
            setLoading(false)
        }
        getLogs()
    },[nav , HandleLogout , filter])
    const handleRowsPerPage = async( value : number) => {
        const new_filter : i_search_filter = { ...filter}
        new_filter.rows_per_page = value
        new_filter.page = 1
        setFilter(new_filter)
    }
    const pagination_props : i_pagination_new = {
        filter : filter,
        onPageChange: (event: React.ChangeEvent<unknown> , page : number) => {
          const new_filter = { ...filter }
          new_filter.page = page
          setFilter(new_filter)
        },
        count : count,
        handleRowsPerPage : handleRowsPerPage,
        title : 'Jobs'
    }
    const logs_headers : i_tbl_header[] = [
        { value : "Date added" , css : { ...header_row, } , portion : 2 , },
        { value : "Action" , css : { ...header_row } , portion : 1 },
        { value : "Output" , css : { ...header_row } , portion : 1 },
        { value : "Data" , css : { ...header_row} , portion : 4},
        { value : "Ip" , css : { ...header_row } , portion : 2 },
        { value : 'Action', css : { ...header_row}, portion :1  }
    ]
    const handleRow = () => {
    }
    const  handleSort = () => {
    }
    const table_v2_props : i_table = {
        data : data,
        title : 'No logs yet, this suspicious..',
        loading : loading,
        headers : logs_headers,
        callback : handleRow,
        setApiError : setApiError,
        row_model : "logs",
        pagination : pagination_props
    }
    const search_input_props : i_log_search_props = {
        search : filter,
        loading : loading,
        callback : setFilter,
    }
    const table_tool_bar_props : i_top_tbl_bar = {
        title : '',
        callback : () => handleSort(),
        inputs : [<LogsFilter {...search_input_props} />],
        count : data.length,
        add : false
    }
    const alert_props : i_alert_props = {
        event : api_error,
        handleClose : () => setApiError({ open : false, promise : empty_promise }),
        type : 'simple',
        mobile : props.mobile
    }
    return (
        <Box sx={{ }}>
            <Box sx={{ minHeight : '91vh'}}>
                { loading ? <CircularUnderLoad type={"full"} /> :
                    <Box>
                        <Box>
                            <Box sx={{ display : 'flex' , justifyContent : "center" , paddingTop : '1vh'}}><Typography variant={'h4'}>Logs</Typography></Box>
                                <Box sx={{ padding: '24px'}}>
                                    <TopTableBar {...table_tool_bar_props}/>
                                    <Box sx={tbl_boxing}>
                                        <Tbl {...table_v2_props} />
                                    </Box>
                                </Box>
                        </Box>
                    </Box>
                }
                <AlertDialog {...alert_props}/>
             
            </Box>
            <Footer type={'center'} {...props}/>
        </Box>
    )
}

export default Logs