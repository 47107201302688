


const header_row = { textAlign : 'center'}

const header_format = {
    fontSize : 14,
    fontWeight : 600 , 
    alignSelf : 'center', 
}
const header_style = { borderRight : '1px solid gray' , borderBottom : '1px solid gray' , display: 'flex', justifyContent: "center"} 
   
const header_text = { textAlign : 'center' , fontSize : 22 , fontWeight : 800 , padding : '10px', alignSelf: 'center' }
const row_style = { alignSelf : 'center' , borderRight : '1px solid gray'}
const row_text = { textAlign : 'center' , fontSize : 18 , fontWeight : 600 }
const tbl_boxing = { padding:'5px' , marginTop : '1vh' , height : 'auto' }

export { header_row , header_format , tbl_boxing , header_style , header_text , row_style , row_text}