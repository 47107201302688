

/*
 * Date: 2024
 * Description: Special modal mody for image view
 * Author: Philippe Leroux @ skitsc
 */


//Modules
import { useState } from "react"
import { Box , Typography , IconButton } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

//Interfaces
import { i_modal_body_view } from "../../../interfaces/utility.interface"

const ModalBodyImgView = ( props : i_modal_body_view ) => {
    const [ index , setIndex ] = useState<number>(0)
    const handlePrevious = () => {
        setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : props.path.length - 1));
    };

    const handleNext = () => {
        setIndex((prevIndex) => (prevIndex < props.path.length - 1 ? prevIndex + 1 : 0));
    };
    return (
        <Box>
            <Box sx={{ display : 'flex' , justifyContent : 'center' , marginBottom : '2vh'}}>
                <Typography sx={{ fontSize : 22 , fontWeight : 800}} >{props.title}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {index !== 0 &&
                <IconButton onClick={handlePrevious} disabled={props.path.length <= 1}>
                    <ArrowBackIcon />
                </IconButton>
                }
                <Box
                    alt={props.alt}
                    component={'img'}
                    src={props.prepath + props.path[index]}
                    sx={{ maxWidth: '100%', maxHeight: '70vh' }}
                />
                {index !== props.path.length -1 &&
                <IconButton onClick={handleNext} disabled={props.path.length <= 1}>
                    <ArrowForwardIcon />
                </IconButton>
                }
            </Box>
        </Box>
    )
}

export default ModalBodyImgView