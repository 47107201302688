/*
 * Date: 2024
 * Description: Basic uploads component
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { ReactElement , useRef , useContext , memo, Fragment } from "react";
import { Grid , Typography , Box , IconButton , Tooltip } from "@mui/material";

//Components

//Interfaces && types
import { i_file_uploads_props, i_img_display } from "../../interfaces/utility.interface";
import { t_file_types } from "../../types/types";

//Styles
import { sub_title, upload_box_style } from "../../styles/form.styles";
import { form_img_card_style , img_style_form } from "../../styles/main.styles";

//Middlewares
import { m_picture_validation } from "../../validation/utility.middleware";

//Icons
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import EditIcon from '@mui/icons-material/Edit';
//Context
import { MainContext } from "../../context/context";

//Api url
const api_url = process.env.REACT_APP_API_URL

const icon_style = {
    position: 'absolute',
    top: 2,
    right: 2,
    color: 'white',
    width: '10px',
    height : '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', 
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)', 
    },
};
const setUrl = (variable: File | string): string => {
    if (variable instanceof File) {
        return URL.createObjectURL(variable);
    } else if (typeof variable === 'string') {
        return variable;
    }
    return '' 
};

const UploadsInputs = memo(( props : i_file_uploads_props ) : ReactElement => {
    const { config } = useContext(MainContext);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const S = props
    const W = props.box_width
    const getFileNames = (files: File[]) : string =>{
        return files.map((file : File) => file.name).join(', ');
    }
    const handleRemove = ( file : string | File, type : t_file_types ) => {
        if(type === 'Upload'){
            const new_assets = [...S.assets]
            const filteredArray = new_assets.filter(item => item !== file);
            S.setAssets(filteredArray)
        }
        if(type === 'Local'){
            const new_uploads = [...S.uploads]
            const filteredArray = new_uploads.filter(item => item !== file);
            S.setUploads(filteredArray)
        }
    }
    const handleFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
        if (selectedFiles) {
            const validFiles = Array.from(selectedFiles).filter(file => m_picture_validation(file , 'allowed'));
            const invalidFiles = Array.from(selectedFiles).filter(file => !m_picture_validation(file, 'allowed'));
            const total = validFiles.length + S.uploads.length + S.assets.length
            if(total <= S.max){
                if (validFiles.length > 0) {
                    const updatedUploads = [...S.uploads, ...validFiles];
                    S.setUploads(updatedUploads);
                    S.setEuploads('');
                } 
                if(invalidFiles.length > 0) {
                    var str = getFileNames(invalidFiles)
                    S.setEuploads('File extension is invalid please chose one of : (jpg, jpeg, png, ou gif). Files : '+str+ ' has not been uploaded.');
                }
            }else{
                var valid : boolean = false;
                if(S.min !== undefined){
                    if(S.min === total) valid = false
                }
                if(valid)S.setApiError({ open : true, promise : { type : 'Failed' , message : "Please select only " + S.max + " files in total !"  , data : []} });
            }
        }
      };
      const f_return_tooltip = ( type : 'img' | 'delete' , name : string , img : string | File) => {
        const isFile = ( variable : string | File ) : variable is File => {
            return variable instanceof File;
        }
        if(type === 'delete'){
            if(name === 'uploads..' && isFile(img)){
                return 'Remove new uploaded file ' + img.name
            }else{
                return 'Remove ' +name+ ' ?'
            } 
        }
        if(type === 'img'){
            if(name === 'uploads..' && isFile(img)){
                return img.name
            }else{
                return name
            } 
        }
        return ''
     }
    const ImgCards =( props : i_img_display ) => {
        const name = props.type === 'Upload' ? props.img.toString() : 'uploads..';
        const src = props.type === 'Upload' ? api_url + props.path + props.img.toString() : setUrl(props.img);
        return ( 
                <Box sx={[form_img_card_style, props.exists && S.max > 1 && { border : '1px solid blue' , padding : '6px'}]}>
                    <Tooltip title={f_return_tooltip('img' , name , props.img)}>
                    <img alt={name} style={img_style_form} src={src}/>
                    </Tooltip>
                    <Tooltip title={f_return_tooltip('delete' , name , props.img)}>
                        <IconButton sx={icon_style} onClick={() => props.handleRemove(props.img, props.type)}><HighlightOffIcon/></IconButton>
                    </Tooltip>
                </Box>
        )
    }
    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const validFiles = Array.from(files).filter(file => m_picture_validation(file , 'allowed'));
            const invalidFiles = Array.from(files).filter(file => !m_picture_validation(file, 'allowed'));
            const total = validFiles.length + S.uploads.length + S.assets.length
            if(total <= S.max){
                if (validFiles.length > 0) {
                    const updatedUploads = [...S.uploads, ...validFiles];
                    S.setUploads(updatedUploads);
                    S.setEuploads('');
                } 
                if(invalidFiles.length > 0) {
                    var str = getFileNames(invalidFiles)
                    S.setEuploads('File extension is invalid please chose one of : (jpg, jpeg, png, ou gif). Files : '+str+ ' has not been uploaded.');
                }
            }else{
                var valid : boolean = false;
                if(S.min !== undefined){
                    if(S.min === total) valid = false
                }
                if(valid)S.setApiError({ open : true, promise : { type : 'Failed' , message : "Please select only " + S.max + " files in total !"  , data : []} });
            }
        }
    };
    const handleBoxClick = () => {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      };
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    return (
        <Box>
            <Grid container mb={1} mt={1}>
                <Grid item xs={2} sx={{ display : 'flex'}}>
                    <Typography sx={sub_title}>{props.title}</Typography>
                </Grid>
                <Grid item xs={1}>  
                    { props.callback === undefined &&
                        <IconButton onClick={handleBoxClick} sx={{ display : 'block', height : '80px' , width : '80px' , marginLeft : 'auto' , marginRight : 'auto' , marginTop : '1vh'}}>
                            <AddAPhotoIcon color={'primary'} sx={{ height : '40px' , width : '40px'}} />
                        </IconButton>
                    }
                </Grid>
                <Grid item xs={9} sx={{ display : 'flex'}}>
                    <Box sx={[{ width : W === undefined ? '550px' : W } , S.Euploads !== '' ? { border : '2px solid red'} : {  }]}>
                        <Box sx={{ width : "100%" , height : props.mobile ? '15vh' : '12vh' , border : '1px solid gray'}}>
                            <Box sx={upload_box_style} onDragOver={handleDragOver} onDrop={handleDrop} >
                                <>
                                { props.uploads.length > 0 || props.assets.length > 0 ? 
                                <>
                                    {
                                        props.assets.map((img, index) => (
                                            <Fragment key={index} >
                                            <ImgCards index={index} img={img} type={'Upload'} handleRemove={handleRemove} exists={true} path={S.path} />
                                            </Fragment>
                                        ))}
                                    
                                    {
                                        props.uploads.map((file, index) => (
                                            <Fragment key={index} >
                                            <ImgCards index={index} img={file} type={'Local'} handleRemove={handleRemove} exists={false} path={S.path}/>
                                            </Fragment>
                                        ))
                                    }
                                </>
                                : 
                                <Grid container sx={{ marginTop : '1.5vh' }}>
                                    <Grid item xs={12} sx={{  }}>
                                        <Box>
                                            <Box sx={{ color : config.primary_color ? config.primary_color : 'red' ,   cursor : 'pointer' , fontSize : props.mobile ? 14 : 18 , textAlign : 'center' ,width : '100%'}}onClick={handleBoxClick}>Click to upload</Box>
                                            <Box sx={{ color : 'gray' , fontSize : props.mobile ? 14 : 18 , textAlign : 'center' ,width : '100%'}}> { "or drag and drop "}</Box>      
                                        </Box>   
                                    </Grid>
                                        <Grid item xs={12} sx={{ display : 'flex' , justifyContent : 'center'}}>                 
                                            <Box sx={{ color : 'gray' , fontSize : props.mobile ? 14 : 18 , textAlign : 'center' ,width : '100%'}}> SVG , PNG , JPG or GIF ( max 800x400px )</Box>
                                        </Grid>
                                </Grid>
                                }
                                <input type="file" ref={fileInputRef} onInput={handleFiles} multiple hidden/>
                                </>
                            </Box>
                        </Box>
                        
                        {S.Euploads !== '' && <Typography sx={{ textAlign : 'center' , color : 'red' , textDecoration : 'underline'}}>{S.Euploads}</Typography> }
                    </Box>
                     { props.callback!== undefined && 
                        <Box>
                            <IconButton onClick={() =>props.callback !== undefined && props.callback('path')}>
                                <EditIcon />
                            </IconButton>
                    </Box>
                     
                     }
                </Grid>
            </Grid>
        </Box>
    ) 
    
})

export default UploadsInputs