/*
 * Date: 2024
 * Description: Support page
 * Author: Philippe Leroux @ skitsc
 */

import { Box, Typography } from "@mui/material"
import { ReactElement } from "react"

//Interfaces && types
import { i_initial_props } from "../interfaces/utility.interface"
import Footer from "../components/utility/footer"

const Support = ( props : i_initial_props ) : ReactElement => {
    return (
        <Box>
            <Box sx={{ minHeight : '91vh'}}>
                <Box sx={{ display : 'flex' , justifyContent : 'center' , paddingTop : '5vh' ,  marginLeft : '5%' , marginRight : '5%' }}>
                    <Box sx={{ border: '1px solid gray', padding: '10px', maxWidth: '800px' }}>
                        <Typography sx={{ fontWeight: 800, textAlign: 'center', fontSize: 22 }}>Support</Typography>
                        
                        <Typography sx={{ fontWeight: 800, fontSize: 20 }}>Contact Information</Typography>
                        <Typography>If you need assistance or have any questions regarding our app, please contact us:</Typography>
                        
                        <Typography sx={{ fontWeight: 800, fontSize: 20 }}>Email</Typography>
                        <Typography>General Inquiries: pleroux@skitsc.com</Typography>
                        
                        <Typography sx={{ fontWeight: 800, fontSize: 20 }}>Feedback</Typography>
                        <Typography>We value your feedback! Please share your thoughts and suggestions with us at pleroux@skitsc.com.</Typography>
                    </Box>
                </Box>
            </Box>
            <Footer type={'center'} {...props}/>
        </Box>
    )
}


export default Support