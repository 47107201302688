/*
 * Date: 2024
 * Description: Dedicated body for yes no modal
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { Box  , Typography , Button } from "@mui/material"

//Interfaces
import { i_prompt_body } from "../../../interfaces/utility.interface"

const PromptBody = (props : i_prompt_body) => {

    return(
        <Box component={'div'}>
            <Typography sx={{ textAlign : 'center'}} variant={"h5"}>{props.title}</Typography>
            <Typography sx={{ textAlign : 'center'}} variant={"h6"}>{props.content}</Typography>
            <Box sx={{display : 'flex' , justifyContent : 'center' , placeContent : 'space-evenly' , marginTop : '1vh'}}>
                <Button variant="contained" onClick={() => props.callback()}>Yes</Button>
                <Button variant="contained" onClick={props.handleClose}>No</Button>
            </Box>
        </Box>
    )
}
export default PromptBody