/*
 * Date: 2024
 * Description: Cookies information page
 * Author: Philippe Leroux @ skitsc
 */

import { Box, Typography } from "@mui/material"
import { ReactElement } from "react"

//Interfaces && types
import { i_initial_props } from "../interfaces/utility.interface"
import Footer from "../components/utility/footer"

const Cookies = ( props : i_initial_props ) : ReactElement => {
    const title = { fontWeight : '800' , fontSize : '1.2rem'}
    return (
        <Box>
            <Box sx={{ minHeight : '91vh'}}>
                <Box sx={{ display : 'flex' , justifyContent : 'center' , paddingTop : '5vh' ,  marginLeft : '5%' , marginRight : '5%' }}>
                    <Box sx={{ border : '1px solid gray' , padding : '10px', maxWidth : '800px'}}>
                    <Typography sx={{ fontWeight : 800 , textAlign : 'center' , fontSize : 22}}>Cookie Policy</Typography>
                        <Typography sx={{ fontWeight : 800 , fontSize : 20}}>Introduction</Typography>
                                <Typography>Our web application uses a single cookie to facilitate user authentication. This cookie is essential for the proper functioning of the app.</Typography>
                                <Typography sx={title}> 1. Authentication Cookie</Typography>
                                <Typography>Name: authToken</Typography>
                                <Typography>Purpose: This cookie is used to store a JSON Web Token (JWT) that authenticates the user. It allows us to identify logged-in users and manage their sessions securely.</Typography>
                                <Typography>Type: Essential</Typography>
                                <Typography>Duration: This cookie is stored for the duration of the user's session. It expires when the user logs out or after a predetermined period of inactivity.</Typography>
                                <Typography>Data Collected: The JWT stored in this cookie contains encoded information that uniquely identifies the user. It does not contain any personal data in plain text.</Typography>
                                <Typography>Security: The JWT is securely signed to prevent tampering and is transmitted over HTTPS to ensure confidentiality and integrity.</Typography>
                                <Typography sx={title}>Contact Us</Typography>
                                <Typography>If you have any questions about our use of cookies, please contact us at pleroux@skitsc.com.</Typography>
                                
                    </Box>
                </Box>
            </Box>
            <Footer type={'center'} {...props}/>
        </Box>
    )
}


export default Cookies