/*
 * Date: 2024
 * Description: Component for user informations.
 * Author: Philippe Leroux @ skitsc
 */


//Modules
import { memo , ChangeEvent } from "react"
import { Box , Grid , Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"

//Interfaces && types
import { i_profile_informations_props } from "../../interfaces/user.interface"

//Components
import ProfileItem from "./profile.row"
//Styles
import { sub_title } from "../../styles/form.styles"

const ProfileInformation = memo(( props : i_profile_informations_props ) => {
    const F = props.form
    const E = props.errors
    const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        var clean_name = name.split('-profile')
        if(clean_name[0] === 'tortue') clean_name = ['email']
        props.setForm({...F, [clean_name[0]] : value})
    }
    const M = props.mobile
    return (
        <Box>
            <Grid container>
            <Grid item xs={M ? 4  : 3}>
                <Typography sx={sub_title}> Your email</Typography>
                </Grid>
                <Grid item xs={M ? 8 :9}>
                    <ProfileItem value={F.email} title="email" width={M ? "200px" : "350px" } setValue={handleChange} error={E.email} mobile={props.mobile}  />
                </Grid>
                <Grid item xs={M ? 4 : 3}>
                    <Typography sx={sub_title}> First name*  </Typography>
                </Grid>
                <Grid item xs={M ? 8 : 9}>
                    <ProfileItem value={F.first_name} title="first_name" width={M ? "200px" : "350px" } setValue={handleChange}  error={E.first_name} mobile={props.mobile} />
                </Grid>
                <Grid item xs={M ? 4 : 3}>
                    <Typography sx={sub_title}>Last name*  </Typography>
                </Grid>
                <Grid item xs={M ? 8 : 9}>
                    <ProfileItem value={F.last_name} title="last_name" width={M ? "200px" : "350px" } setValue={handleChange}  error={E.last_name} mobile={props.mobile} />
                </Grid>
                <Grid item xs={M ? 4 : 3}>
                    <Typography sx={sub_title}>Phone:</Typography>
                </Grid>
                <Grid item xs={M ? 8 : 9}> 
                    <ProfileItem value={F.phone_one} title="phone_one" width={M ? "200px" : "350px" } setValue={handleChange}  error={E.phone_one} mobile={props.mobile}/>
                </Grid>
                <Grid item xs={M ? 4 : 3}>
                    <Typography sx={sub_title}>Secondary phone :</Typography>
                </Grid>
                <Grid item xs={M ? 8 : 9} mb={2}> 
                    <ProfileItem value={F.phone_two} title="phone_two" width={M ? "200px" : "350px" } setValue={handleChange} error={E.phone_two} mobile={props.mobile}/>
                </Grid>
                <Grid item xs={12} sx={{ borderTop : '1px gray solid'}}>
                    <Typography sx={[sub_title , { fontSize : 18}]} mb={3} mt={1}>Address </Typography>
                </Grid>
                {M ?
                <>
                    <Grid item xs={M ? 4 : 3}>
                        <Typography sx={sub_title}>Unit number</Typography>
                    </Grid>
                    <Grid item xs={M ? 8 : 9}> 
                        <ProfileItem width="200px" value={F.unit_number} title="unit_number" setValue={handleChange} error={E.unit_number} mobile={props.mobile}/>
                    </Grid>
                    <Grid item xs={M ? 4 : 3}>
                        <Typography sx={sub_title}>Civic number*</Typography>
                    </Grid>
                    <Grid item xs={M ? 8 : 9}> 
                        <ProfileItem width="200px" value={F.door_number} title="door_number" setValue={handleChange} error={E.door_number} mobile={props.mobile}/>
                    </Grid>
                    <Grid item xs={M ? 4 : 3}>
                        <Typography sx={sub_title}>Street*</Typography>
                    </Grid>
                    <Grid item xs={M ? 8 : 9}> 
                        <ProfileItem width="200px"  value={F.street} title="street" setValue={handleChange}  error={E.street} mobile={props.mobile}/>
                    </Grid>
                    <Grid item xs={M ? 4 : 3}>
                        <Typography sx={sub_title}>City*</Typography>
                    </Grid>
                    <Grid item xs={M ? 8 : 9}> 
                        <ProfileItem width="200px" value={F.city} title="city" setValue={handleChange} error={E.city} mobile={props.mobile}/>
                    </Grid>
                    <Grid item xs={M ? 4 : 3}>
                        <Typography sx={sub_title}>Province*</Typography>
                    </Grid>
                    <Grid item xs={M ? 8 : 9}> 
                        <ProfileItem width="200px" select={true} value={F.state} title="state" setValue={handleChange}  error={E.state} mobile={props.mobile}/>
                    </Grid>
                    <Grid item xs={M ? 4 : 3}>
                        <Typography sx={sub_title}>Postal code*</Typography>
                    </Grid>
                    <Grid item xs={M ? 8 : 9}> 
                        <ProfileItem width="200px" value={F.zip} title="zip" setValue={handleChange}  error={E.zip} mobile={props.mobile}/>
                    </Grid>
                </> :
                <>
                    <Grid item xs={M ? 4 : 3}>
                        <Typography sx={sub_title}>Unit number / Civic number* / Street*</Typography>
                    </Grid>
                    <Grid item xs={M ? 8 : 9}> 
                        <ProfileItem width="175px" value={F.unit_number} title="unit_number" setValue={handleChange} error={E.unit_number} mobile={props.mobile}/>
                        <ProfileItem width="175px" value={F.door_number} title="door_number" setValue={handleChange} error={E.door_number} mobile={props.mobile}/>
                        <ProfileItem width="350px"  value={F.street} title="street" setValue={handleChange}  error={E.street} mobile={props.mobile}/>
                    </Grid>
                    <Grid item xs={M ? 4 : 3}>
                        <Typography sx={sub_title}> City* / Province* / Postal code*</Typography>
                    </Grid>
                    <Grid item xs={M ? 4 : 3}> 
                        <ProfileItem width="350px" value={F.city} title="city" setValue={handleChange} error={E.city} mobile={props.mobile}/>
                        <ProfileItem width="175px" select={true} value={F.state} title="state" setValue={handleChange}  error={E.state} mobile={props.mobile}/>
                        <ProfileItem width="175px" value={F.zip} title="zip" setValue={handleChange}  error={E.zip} mobile={props.mobile}/>
                    </Grid>
                </>
                }
                <Grid item xs={12}> 
                    <LoadingButton loading={props.loading} onClick={() => props.callback('information')} variant="contained" color="primary" sx={{ display : 'block' , marginLeft : 'auto' , marginRight : 'auto' , width:'300px' }}>Update</LoadingButton>
                </Grid>
            </Grid>
        </Box>
    )

})

export default ProfileInformation