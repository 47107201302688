/*
 * Date: 2024
 * Description: Entry point of the application.
 * Author: Philippe Leroux @ skitsc
 */


//Modules
import { useState, useEffect , useMemo , useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { CssBaseline } from "@mui/material";

//Menu
import UI from "./components/ui/ui";

//pages
import Login from "./page/login";
import Dashboard from "./page/dashboard";
import Logout from "./page/logout";
import Profile from "./page/profile";
import Users from "./page/settings/users";
import Help from "./page/help";
import AppConfig from "./page/settings/app.config";
import Punch from "./page/punch";
import Timesheets from "./page/timesheets";
import MailConfig from "./page/settings/mail.config";
import PublicLogin from "./page/public.login";
import Logs from "./page/settings/logs";
import Support from "./page/support";
import Terms from "./page/terms";
import Cookies from "./page/cookies";
import Privacy from "./page/privacy";

//Styles
import "./index.css"

//Theming
import { ThemeProvider } from '@mui/material/styles';
import { generateTheme } from "./theme"

//Context
import { MainContext , ThemeContext } from "./context/context";
import { MainHook, ThemeHook } from "./context/hook";
import { SocketProvider , SocketContext, socket_id} from "./context/socket.context";

//Api
import { f_fetch } from "./api/fetch";

//Types and interfaces
import { i_promise } from "./interfaces/utility.interface";

//Components
import LoadingAuth from "./components/auth/loading.auth";


const App = () => {
    const ContextState = MainHook();
    const ThemeState = ThemeHook();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ mobile , setMobile ] = useState(false);
    const socket = useContext(SocketContext);   

    useEffect(() => {
        const f_fetch_handler = async () => {
            setIsLoading(true);
            const get_config : i_promise = await f_fetch('/config', 'GET', false, null);
            if(get_config.type === 'Success'){
                ContextState.setCurrentConfig(get_config.data);
                ThemeState.setCurrentColor(get_config.data)
            }
            const api_rep: i_promise = await f_fetch('/auth' , 'POST' , true , null);
            if (api_rep.type === "Success") {
                ContextState.HandleLogin(api_rep.data);
                socket.emit('join', { roomId : api_rep.data.type , user_id : api_rep.data._id , socket_id : socket_id});
            }
                setIsLoading(false);
            };
            function handleResize() {;
                const mobileWidthBreakpoint = 600;
                const isMobile = window.innerWidth <= mobileWidthBreakpoint;
                setMobile(isMobile);
            }
            f_fetch_handler();
            handleResize();
            window.addEventListener('resize', handleResize);
            return () => {
            window.removeEventListener('resize', handleResize);
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);
    const useAuth = () => {
        if (ContextState.authenticated) {
            return true
        } else return false
    } 
    const isAdmin = () => {
        if (ContextState.authenticated) {
            if(ContextState.user.type === 'Admin'){
                return true
            }else{
                return false
            }
        } else return false
    }
    const PrivateRoute = ({ children }: any) => {
        const auth = useAuth();
        return auth ? children : <Navigate to="/" />;
    }
    const AdminRoute = ({ children }: any) => {
        const auth = isAdmin();
        return auth? children : <Navigate to="/" />;
    }
    const PublicRoutes = ({ children }: any) => {
        const auth = useAuth();
        return !auth ? children : <Navigate to="/overview" />;
    }
    const themeWithLocale = useMemo(
        () => generateTheme(
            ThemeState.mode, // Pass your mode
            ThemeState.primary_color,// Pass your primaryColor
            ThemeState.secondary_color, // Pass your secondaryColor
            ThemeState.mode === 'Dark' ? "#222" : '#FFF', // Pass your backgroundColor
            ThemeState.mode === 'Dark' ? '#555' : '#111', // Pass your borderColor
            ThemeState.mode === 'Dark' ? '#FFF' : '#000', // Pass your textColor
            ThemeState.selected_color, // Pass your selectedActionColor
            ThemeState.hover_color // Pass your hoverActionColor
        ),
        [ThemeState.mode , ThemeState.primary_color , ThemeState.secondary_color , ThemeState.hover_color , ThemeState.selected_color ],
    );
    const props = {
        mobile : mobile,
    }
    return (
        <MainContext.Provider value={ContextState}>
        <SocketProvider>
            <ThemeContext.Provider value={ThemeState}>
            <ThemeProvider theme={themeWithLocale}>
                <CssBaseline />
                {isLoading ? (
                    <LoadingAuth/>
                ) : (
                    <Routes>
                    <Route path="/" element={<PublicRoutes><PublicLogin {...props} /></PublicRoutes>}/>
                    <Route path="/admin" element={<PublicRoutes><Login {...props}/></PublicRoutes>}/>
                        <Route path="/overview" element={<PrivateRoute><UI Item={ContextState.user.type === 'Admin' ? <Dashboard {...props}/> : <Punch {...props}/> } mobile={mobile}/></PrivateRoute>}/>
                        <Route path="/settings/users" element={<AdminRoute><UI Item={<Users {...props}/>} mobile={mobile}/></AdminRoute>}/>
                        <Route path="/settings/app.config" element={<AdminRoute><UI Item={<AppConfig {...props} />} mobile={mobile}/></AdminRoute>}/>
                        <Route path="/settings/mail.config" element={<AdminRoute><UI Item={<MailConfig {...props} />} mobile={mobile}/></AdminRoute>}/>
                        <Route path="/settings/logs" element={<AdminRoute><UI Item={<Logs {...props} />} mobile={mobile}/></AdminRoute>}/>
                        <Route path="/profile" element={<PrivateRoute><UI Item={<Profile {...props}/>} mobile={mobile}/></PrivateRoute>}/>
                        <Route path="/timesheets" element={<AdminRoute><UI Item={<Timesheets {...props} />} mobile={mobile}/> </AdminRoute>}/>
                        <Route path="/help" element={<PrivateRoute><UI Item={<Help {...props} />}mobile={mobile}/></PrivateRoute>}/>
                        <Route path="/support" element={<PrivateRoute><UI Item={<Support {...props} />}mobile={mobile}/></PrivateRoute>}/>
                        <Route path="/terms" element={<PrivateRoute><UI Item={<Terms {...props} />}mobile={mobile}/></PrivateRoute>}/>
                        <Route path="/cookies" element={<PrivateRoute><UI Item={<Cookies {...props} />}mobile={mobile}/></PrivateRoute>}/>
                        <Route path="/privacy" element={<PrivateRoute><UI Item={<Privacy {...props} />}mobile={mobile}/></PrivateRoute>}/>
                    <Route path="/logout" element={<Logout {...props}/>}/>
                    </Routes>
                )}
                </ThemeProvider>
                </ThemeContext.Provider>
            </SocketProvider>
        </MainContext.Provider>
    );
}

export default App;
