


/*
 * Date: 2024
 * Description: Users rows reworked for table
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { Box , Grid , Typography , IconButton , Tooltip  } from "@mui/material"
import { ReactElement } from "react"

//Interfaces && types
import { i_logs, i_table_v2_row_props } from "../../../../interfaces/utility.interface";

//Icons
import InfoIcon from '@mui/icons-material/Info';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import StorageIcon from '@mui/icons-material/Storage';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

//Utils
import { f_timestamp_to_date} from "../../../../utils/utility";


const LogsRow = ( props : i_table_v2_row_props) : ReactElement => {


    const ReturnType = ( row : i_logs ) => {
        return (
            <Tooltip title={row.action}>
                <IconButton>
                    {row.action === "login" ? <LoginIcon /> :
                     row.action === 'logout' ? <LogoutIcon/> :
                     row.action === 'cron' ? <StorageIcon/> 
                    : <QuestionMarkIcon/>}
                </IconButton>
            </Tooltip>
        )
    }
    return (
        <Box sx={{ borderLeft : '1px solid #D3D3D3' , borderRight : '1px solid #D3D3D3' , borderBottom : '1px solid #D3D3D3'}}>
        <Grid container sx={{ padding : '6px'}}>
            <Grid item xs={props.headers[0].portion} sx={{}}>
                <Box sx={{ display : 'flex' , marginTop : '0.4vh'  }}>
                    <Typography sx={{ fontSize : 14 }}>{f_timestamp_to_date(props.row.timestamp , 'long')}</Typography>
                </Box>
            </Grid>
            <Grid item xs={props.headers[1].portion} sx={{ alignSelf : 'center'}}>
                <Typography sx={{ alignSelf : 'center' , fontSize : 14}}>{ReturnType(props.row)}</Typography>
            </Grid>
            <Grid item xs={props.headers[2].portion} sx={{ alignSelf : 'center' , display : 'flex'}}>
                <Typography sx={{ alignSelf : 'center' , fontSize : 14}}>{props.row.success === 1 ? <CheckCircleIcon sx={{ color : 'green'}}/> : <DangerousIcon sx={{ color : 'red'}} />}</Typography>
            </Grid>
            <Grid item xs={props.headers[3].portion} sx={{ alignSelf : 'center' }}>
                {props.row.data}
            </Grid>
            <Grid item xs={props.headers[4].portion} sx={{ alignSelf : 'center' }}>
                {props.row.ip}
            </Grid>
            <Grid item xs={props.headers[5].portion} sx={{ alignSelf : 'center' }}>
                <IconButton onClick={() => props.callback('view' , 'table')}>
                    <InfoIcon/>
                </IconButton>
            </Grid>
          
        </Grid>
    </Box>
    )
}

export default LogsRow