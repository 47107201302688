

/*
 * Date: 2024
 * Description: Basic events logs of the current day
 * Author: Philippe Leroux @ skitsc
 */


//Modules
import { Grid, IconButton, Typography , Box } from "@mui/material"

//Interfaces && types
import { i_punch, i_punch_display_props } from "../../interfaces/timesheet.interface"

//Utilitys
import { f_timestamp_to_date } from "../../utils/utility"

//Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

//Styles
import { header_text , header_style , row_style , row_text } from "../../styles/tbl.styles";

const PunchDisplay = ( props : i_punch_display_props ) => {
    const T = props.type === 'Admin' ? 3.75 : 4.5
    const handleCallback = ( row : i_punch , type : 'view' | 'form' | 'disable') => {
        if(props.callback!== undefined) props.callback(type, row)
    }


    return (
        <Box>
            <Box sx={{ display : 'flex' , justifyContent : 'center', width : '100%',  border : '1px solid gray', mx : 'auto' ,maxWidth : '1500px' }}>
            <Grid container>
                {props.type !== 'Admin' &&
                    <Grid item xs={12} sx={{ borderBottom : '1px solid gray'}}>
                        <Typography sx={[header_text , props.mobile && {fontSize : 18} ]}> Today time's sheet</Typography>
                    </Grid>
                }
                <Grid item xs={T} sx={ header_style }>
                    <Typography sx={[header_text , props.mobile && {fontSize : 18} ]}> Start </Typography>
                </Grid>
                <Grid item xs={T} sx={header_style}>
                    <Typography sx={[header_text , props.mobile && {fontSize : 18} ]}> End </Typography>
                </Grid>
                <Grid item xs={3} sx={header_style}>
                    <Typography sx={[header_text , props.mobile && {fontSize : 18} ]}> Type</Typography>
                </Grid>
                { props.type === 'Admin' &&
                    <Grid item xs={1.5} sx={{  borderBottom : '1px solid gray'}}>
                        <Typography sx={header_text}>Action</Typography>
                    </Grid>
                }
                {props.data.map((punch : i_punch , index : number) => (
                    <Grid container key={index}  sx={{ display : 'flex' , borderBottom : '1px solid gray'}}>
                        <Grid item xs={T} sx={row_style}> <Typography sx={row_text}>{ f_timestamp_to_date( punch.punch_in , 'long' )}</Typography></Grid>
                        <Grid item xs={T} sx={row_style}> <Typography sx={row_text}> { punch.punch_out !== 0  ? f_timestamp_to_date( punch.punch_out , 'long') : '.. ongoing'}</Typography></Grid>
                        <Grid item xs={3} sx={row_style}> <Typography sx={row_text}>{punch.type} </Typography></Grid>
                        { props.type === 'Admin' &&
                            <Grid item xs={1.5} sx={{ display : 'flex' , justifyContent : 'center'}}>
                                <IconButton sx={{ color : 'blue' , marginRight : 'auto'}} onClick={() => handleCallback(punch , 'view')}>
                                    <VisibilityIcon />
                                </IconButton>
                                <IconButton onClick={() => handleCallback(punch , 'form')}>
                                    <EditIcon sx={{ color : 'green' }}/>
                                </IconButton>
                                <IconButton onClick={() => handleCallback(punch , 'disable')} sx={{ marginLeft : 'auto'}}>
                                    <DeleteForeverIcon sx={{ color : 'red' }}/>
                                </IconButton>
                            </Grid>
                        }
                    </Grid>   

                ))}
            </Grid>
            </Box>
        </Box>
    )
}


export default PunchDisplay