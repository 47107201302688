


/*
 * Date: 2024
 * Description: Middleware for form fetches
 * Author: Philippe Leroux @ Skitsc
 */

//Interfaces
import { i_admin_login, i_user , i_user_login, i_user_reset , i_user_rules } from "../interfaces/user.interface";
import { t_method } from "../types/types";  
import { i_app_config, i_app_config_rules } from "../interfaces/setting.interface";
import { i_punch, i_punch_rules } from "../interfaces/timesheet.interface";

//Constants
import { lexique_message } from "../utils/constant";

//Middlewares
import { m_validate_email , m_validate_password , m_validate_empty_fields, m_validate_str, m_validate_phone, m_validate_opt_phone, m_validate_opt_zip_code, m_validate_number,m_validate_email_opt, m_validate_hex_colors,
  m_validate_url_opt, m_validate_opt_nas, m_validate_user_type, m_validate_password_opt,
  m_force_str,
  m_validate_punch_type,
  m_validate_numeric_str} from "./utility.middleware";

const m_validate_login = ( form : i_user_login ) : [ boolean , string , string ] => {
	if(m_force_str(form.code) !== '') return [ false, lexique_message.empty_error, 'code'  ];
	if(form.nip === undefined) return [ false, lexique_message.empty_error, 'nip'  ];
	return [ true , '' , ''  ]
}
const m_validate_admin_login = ( form : i_admin_login , display : 'login' | 'reset' | 'recovery' | 'final' | 'register' ) : [ boolean , string , string ] => {
	if(!m_validate_email(form.email)) return [ false, lexique_message.email_error, 'email'  ];
	if(display === 'login' && !m_validate_password(form.password)) return [ false, lexique_message.password_error , 'password'];
	return [ true , '' , ''  ]
}

const m_validate_recovery_reset = ( form : i_user_reset ) : [ boolean , string , string ] => {
	if(!m_validate_password(form.password)) return [ false, lexique_message.password_error, 'password'  ];
	if(form.password !== form.confirm_password) return [ false, 'Passwords do not match', 'confirm_password'  ];
	return [ true , '' , ''  ]
}
const m_validate_user = ( form : i_user , method : t_method ) : [ boolean , string , string ] => {
    const validation_rules : i_user_rules[] = [
        { field : 'first_name', validate : m_validate_empty_fields , error : lexique_message.empty_error },
        { field : 'first_name', validate : m_validate_str, error : lexique_message.special_error },
        { field : 'last_name', validate : m_validate_empty_fields, error : lexique_message.empty_error},
        { field : 'last_name', validate : m_validate_str, error : lexique_message.special_error },
        { field : 'email', validate : m_validate_email, error : lexique_message.email_error },
        { field : "nas" , validate : m_validate_opt_nas , error : lexique_message.nas_error },
        { field : 'phone_one', validate : m_validate_phone, error : lexique_message.phone_error },
        { field : 'phone_two', validate : m_validate_opt_phone, error : lexique_message.phone_error },
        { field : 'password' , validate : m_validate_password_opt, error : lexique_message.password_error },
        { field : 'confirm_password' , validate : m_validate_password_opt, error : lexique_message.password_error},
        { field : "unit_number", validate : m_validate_str, error : lexique_message.special_error },
        { field : "door_number", validate : m_validate_str, error : lexique_message.special_error },
        { field : "street", validate : m_validate_str, error : lexique_message.special_error },
        { field : "city", validate : m_validate_str, error : lexique_message.special_error },
        { field : "state", validate : m_validate_str, error : lexique_message.special_error },
        { field : "zip", validate : m_validate_opt_zip_code, error : lexique_message.zip_error },
        { field : "type" , validate: m_validate_user_type , error : lexique_message.user_type_error},
        { field : "hour_rate" , validate : m_validate_number, error : lexique_message.numeric_error},
        { field : "start" , validate : m_validate_number , error : lexique_message.numeric_error},
        { field : "end" , validate : m_validate_number , error : lexique_message.numeric_error},
    ]
    const validation_rules_admin : i_user_rules[] = [
        ...validation_rules,
        { field : 'password' , validate : m_validate_password_opt, error : lexique_message.password_error },
        { field : 'confirm_password' , validate : m_validate_password_opt, error : lexique_message.password_error},
    ]
    const validation_rules_user : i_user_rules[] = [
        ...validation_rules,
        { field : 'nip' , validate : m_validate_numeric_str , error : lexique_message.nip_error},
        { field : 'code' , validate : m_validate_empty_fields, error : lexique_message.empty_error},
        { field : 'code' , validate : m_validate_str, error : lexique_message.str_error},
    ]
    if(form.type === 'Admin'){
        for (const validation of validation_rules_admin) {
            const { field, validate, error } = validation;
            if(!validate((form as i_user)[field]))  return [false, error, field];
        }
    }else{
        for (const validation of validation_rules_user) {
            const { field, validate, error } = validation;
            if(!validate((form as i_user)[field]))  return [false, error, field];
        }
    }
    if(method === 'POST'){
        if(form.type === 'Admin'){
        if(!m_validate_password(form.password )) return [ false, lexique_message.password_error , 'password' ];
        if(form.confirm_password !== undefined && !m_validate_password(form.confirm_password )) return [ false, lexique_message.password_error , 'confirm_password' ];
        if(form.password !== form.confirm_password) return [ false, lexique_message.match_error, 'confirm_password' ];
        }
    }
  return [ true , '' , ''  ]
}
const m_validate_profil_information = ( form : i_user ) : [ boolean , string , string ] => {
    const validation_rules : i_user_rules[] = [
      { field : 'first_name', validate : m_validate_empty_fields , error : lexique_message.empty_error },
      { field : 'first_name', validate : m_validate_str, error : lexique_message.special_error },
      { field : 'last_name', validate : m_validate_empty_fields, error : lexique_message.empty_error},
      { field : 'last_name', validate : m_validate_str, error : lexique_message.special_error },
      { field : 'email', validate : m_validate_email, error : lexique_message.email_error },
      { field : 'phone_one', validate : m_validate_phone, error : lexique_message.phone_error },
      { field : 'phone_two', validate : m_validate_opt_phone, error : lexique_message.phone_error },
      { field : "unit_number", validate : m_validate_str, error : lexique_message.special_error },
      { field : "door_number", validate : m_validate_str, error : lexique_message.special_error },
      { field : "street", validate : m_validate_str, error : lexique_message.special_error },
      { field : "city", validate : m_validate_str, error : lexique_message.special_error },
      { field : "state", validate : m_validate_str, error : lexique_message.special_error },
      { field : "zip", validate : m_validate_opt_zip_code, error : lexique_message.zip_error },
    ]
    for (const validation of validation_rules) {
      const { field, validate, error } = validation;
      if(!validate((form as i_user)[field]))  return [false, error, field];
    }
    return [ true , '' , ''  ]
}
const m_validate_profil_passwords = ( form : i_user ) : [ boolean , string , string ] => {
    const validation_rules : i_user_rules[] = [
        { field : 'password' , validate : m_validate_password, error : lexique_message.password_error },
        { field : 'confirm_password' , validate : m_validate_password, error : lexique_message.password_error},
        { field : 'old_password' , validate : m_validate_password, error : lexique_message.password_error}
    ]
    for (const validation of validation_rules) {
    const { field, validate, error } = validation;
    if(!validate((form as i_user)[field]))  return [false, error, field];
    }
    if(form.old_password !== undefined && form.confirm_password !== form.password) return [ true, "Password does not match..", 'confirm_password' ];
    return [ true , '' , ''  ]
}
const m_validate_profil_nip = ( form : i_user ) : [ boolean , string , string ] => {
  const validation_rules : i_user_rules[] = [
      { field : 'old_nip' , validate : m_validate_numeric_str, error : lexique_message.nip_error },
      { field : 'nip' , validate : m_validate_numeric_str, error : lexique_message.nip_error},
      { field : 'confirm_nip' , validate : m_validate_numeric_str, error : lexique_message.nip_error}
  ]
  for (const validation of validation_rules) {
    const { field, validate, error } = validation;
    if(!validate((form as i_user)[field]))  return [false, error, field];
  }
  if(form.old_nip !== undefined && form.confirm_nip !== form.nip) return [ true, "Nip does not match..", 'confirm_nip' ];
  return [ true , '' , ''  ]
}
const m_validate_uploads = ( uploads : any[] , assets : string[] ) : [ boolean , string , string ] => {
    //Some validations at another level here
    return [ true , '' , ''  ]
}
const m_validate_app_config = ( form : i_app_config ) : [ boolean, string, string ] => {
    const validation_rules : i_app_config_rules[] = [
        { field : 'name' , validate : m_validate_empty_fields, error : lexique_message.empty_error},
        { field : 'name' , validate : m_validate_str, error : lexique_message.special_error },
        { field : 'email' , validate : m_validate_email_opt, error : lexique_message.email_error },
        { field : 'url', validate : m_validate_url_opt, error : lexique_message.url_error},
        { field : 'id' , validate : m_validate_str, error : lexique_message.special_error },
        { field : 'country', validate : m_validate_empty_fields, error : lexique_message.empty_error},
        { field : 'country', validate : m_validate_str, error : lexique_message.special_error },
        { field : 'activity', validate : m_validate_empty_fields, error : lexique_message.empty_error},
        { field : 'activity', validate : m_validate_str, error : lexique_message.special_error },
        { field : 'phone' , validate : m_validate_opt_phone, error : lexique_message.phone_error },
        { field : 'phone_two', validate : m_validate_opt_phone, error : lexique_message.phone_error},
        { field : "unit_number", validate : m_validate_str, error : lexique_message.special_error },
        { field : "door_number", validate : m_validate_str, error : lexique_message.special_error },
        { field : "street", validate : m_validate_str, error : lexique_message.special_error },
        { field : "city", validate : m_validate_str, error : lexique_message.special_error },
        { field : "state", validate : m_validate_str, error : lexique_message.special_error },
        { field : "zip", validate : m_validate_opt_zip_code, error : lexique_message.zip_error },
        { field : "primary_color", validate : m_validate_hex_colors, error : lexique_message.color_error},
        { field : "secondary_color", validate : m_validate_hex_colors, error : lexique_message.color_error},
        { field : "selected_color", validate : m_validate_hex_colors, error : lexique_message.color_error},
        { field : "hover_color", validate : m_validate_hex_colors, error : lexique_message.color_error},
    ]
    for (const validation of validation_rules) {
        const { field, validate, error } = validation;
        if(!validate((form as i_app_config)[field]))  return [false, error, field];
    }
    return [ true , '', '' ]
}

const m_validate_punch = ( form : i_punch ) : [ boolean , string , string ] => {
    //Could add more validates here !
    const validation_rules : i_punch_rules[] = [
        { field : 'type' , validate : m_validate_punch_type, error : lexique_message.invalid_type_error},
    ]
    for (const validation of validation_rules) {
        const { field, validate, error } = validation;
        if(!validate((form as i_punch)[field]))  return [false, error, field];
    }
    return [ true , '' , ''  ]
}

 
 
 
export { m_validate_login , m_validate_uploads, m_validate_profil_information, m_validate_profil_passwords ,  m_validate_user ,m_validate_recovery_reset , m_validate_app_config , m_validate_admin_login , m_validate_punch , m_validate_profil_nip }