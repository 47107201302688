

/*
 * Date: 2024
 * Description: Reusable search input
 * Author: Philippe Leroux @ Skitsc
 */


//Modules
import { Box , MenuItem, TextField } from "@mui/material"
import { ReactElement, ChangeEvent} from "react"
//interfaces && types
import { i_log_search_props, i_logs_types } from "../../../interfaces/utility.interface"

const LogsFilter = ( props : i_log_search_props ) : ReactElement => {
    const handleSearch = (e : ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value)
        props.callback({...props.search, search : e.target.value} )
    }
    const action_log_arr : i_logs_types[] = ['login' , 'logout' , 'change_email' , 'event' , 'password_recovery' , 'error' , 'password_change' , 'cron' , 'close_punch']
    return (
        <Box sx={{ display : 'flex' , paddingLeft : '10%' ,}}>
            <TextField size="small" sx={{ width : '200px'}} select value={props.search.search} onChange={handleSearch}>
                <MenuItem value={'Any'}>All</MenuItem>
                {action_log_arr.map((action : string) => (
                    <MenuItem key={action} value={action}>{action}</MenuItem>
                ))}
            </TextField>
        </Box>
    )
}

export default LogsFilter