/*
 * Date: 2024
 * Description: Mail config page logics
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { ReactElement , useState , useEffect , useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

//Contexts
import { MainContext } from "../../context/context";

//Utility
import { f_fetch } from "../../api/fetch";

//Components
import Footer from "../../components/utility/footer";
import MailInputs from "../../components/settings/mail/form/mail.inputs";
import AlertDialog from "../../components/utility/alert";

//Interfaces && types
import { i_initial_props } from "../../interfaces/utility.interface";
import { i_promise } from "../../interfaces/utility.interface";
import { i_mail_config, i_mail_config_errors, i_mail_config_form } from "../../interfaces/setting.interface";
import { i_alert_props , i_snack_alert } from "../../interfaces/utility.interface";

//Constants
import { default_mail_config, mail_errors , empty_promise } from "../../utils/constant";


const MailConfig = ( props : i_initial_props ) : ReactElement => {

    const nav = useNavigate();
    const { HandleLogout } = useContext(MainContext);
    const [ loading, setLoading ] = useState<boolean>(false)
    const [ errors , setErrors ] = useState<i_mail_config_errors>(mail_errors)
    const [ initial, setInitial ] = useState<i_mail_config>(default_mail_config)
    const [ target , setTarget ] = useState<i_mail_config>(default_mail_config)
    const [ api_error, setApiError ] = useState<i_snack_alert>({ open : false , promise : empty_promise});

    useEffect(() => {
        const fetchConfig = async() : Promise<void> => {
            setLoading(true)
            const res : i_promise = await f_fetch('/mail/config' , 'GET' , true , null)
            if(res.type === 'Success'){
                setInitial(res.data)
            }else{
                if(res.type === 'Unauthorized') HandleLogout(nav)
            }
            setLoading(false)
        }
        fetchConfig()
    },[nav , HandleLogout])
    useEffect(() => {
        setTarget(initial)
    },[initial])
    const handleSubmit = async() : Promise<void> => {
        const res = await f_fetch('/mail' , 'PUT' , true , target)
        if(res.type === 'Success'){
            setInitial(target)
        }else{
            if(res.type === 'Unauthorized') HandleLogout(nav)
        }
        setApiError({ open : true, promise : res })
    }
    const handleTest = async() : Promise<void> => {
        const res = await f_fetch('/mail/test' , 'GET' , true , null)
        if(res.type === 'Success'){
        }else{
            if(res.type === 'Unauthorized') HandleLogout(nav)
        }
        setApiError({ open : true, promise : res })
    }
    const handleReset = () : void => {
        setTarget(initial)
    }
    const mail_form_props : i_mail_config_form = {
        loading : loading,
        errors : errors,
        form : target,
        setForm : setTarget,
        callback : handleSubmit,
        reset : handleReset,
        tester : handleTest,
        dev: true,

    }
    const alert_props : i_alert_props = {
        event : api_error,
        handleClose : () => setApiError({ open : false, promise : empty_promise }),
        type : 'simple',
        mobile : props.mobile
    }
    return (
        <Box>
            <Box sx={{ minHeight : '91vh'}}>
                <Box sx={{ marginTop : '2vh' , padding : '3vh'}}><Typography sx={{ fontSize : 22 , fontWeight : 800}}>Manage your mail box credentials</Typography></Box>
                <MailInputs {...mail_form_props} />
            </Box>
            <AlertDialog {...alert_props} />
            <Footer type={'center'} {...props}/>
        </Box>
    )
}

export default MailConfig