/*
 * Date: 2024
 * Description: Main menu component
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { Box } from "@mui/material";

//Menus
import TopBar from "./topbar.menu";

//Components
import ScrollToTop from "../utility/scroll.top";

//Styles
import { app , content , main_container } from "../../styles/main.styles";

//Types && Interfaces
import { i_child_component } from "../../interfaces/utility.interface";

const UI = ( props : i_child_component ) => {
    return (
        <Box component={'div'} sx={app}>
            <Box component={'main'} sx={content}>
                <TopBar {...props} />
                <Box sx={main_container} component={'div'} id="back-to-top-anchor">
                    {props.Item}
                    <ScrollToTop/>
                </Box>
            </Box>
        </Box>
    )
}

export default UI