/*
 * Date: 2024
 * Description: Terms and conditions page
 * Author: Philippe Leroux @ skitsc
 */

import { Box, Typography } from "@mui/material"
import { ReactElement } from "react"

//Interfaces && types
import { i_initial_props } from "../interfaces/utility.interface"
import Footer from "../components/utility/footer"

const Terms = ( props : i_initial_props ) : ReactElement => {
    const title = { fontWeigth : 800, fontSize : '1.2rem'}
    return (
        <Box>
            <Box sx={{ minHeight : '91vh'}}>
                <Box sx={{ display : 'flex' , justifyContent : 'center' , paddingTop : '5vh' ,  marginLeft : '5%' , marginRight : '5%' }}>
                    <Box sx={{ border : '1px solid gray' , padding : '10px', maxWidth : '1200px'}}>
                        <Typography sx={{ textAlign : 'center' , fontWeight : 800 , fontSize : 22}}>Terms and Conditions</Typography>
                        <Typography sx={title}>1. Introduction</Typography>
                        <Typography>
                        Welcome to Clutch punch. These Terms and Conditions govern your use of our web application, which includes logging in, punching in/out, and taking photos during the punch-in process. By using our app, you agree to comply with and be bound by these terms. If you do not agree to these terms, please do not use our app.
                        </Typography>
                        <Typography sx={title}>
                        2. Account Registration
                        </Typography>
                        <Typography>
                        Users must create an account to use the app.
                        You are responsible for maintaining the confidentiality of your account credentials.
                        You agree to provide accurate and complete information during the registration process.
                        </Typography>
                        <Typography sx={title}>
                        3. Use of the App
                        </Typography>
                        <Typography>
                        The app allows you to punch in and out, recording the time and taking a photo during the punch-in process.
                        You agree to use the app for lawful purposes only and not to engage in any activity that interferes with or disrupts the app's functionality.
                        </Typography>
                        <Typography sx={title}>
                        4. Data Collection and Privacy
                        </Typography>
                        <Typography>
                        The app collects and stores data related to your login, punch-in/out times, and photos taken during punch-in.
                        We are committed to protecting your privacy and will handle your personal data in accordance with our Privacy Policy Link in work here.
                        By using the app, you consent to the collection and use of your data as described in the Privacy Policy.
                        </Typography>
                        <Typography sx={title}>
                        5. Intellectual Property
                        </Typography>
                        <Typography>
                        All content and materials in the app, including but not limited to text, graphics, logos, and software, are the property of Xykal or its licensors.
                        You are granted a limited, non-exclusive license to use the app for its intended purpose.
                        You agree not to reproduce, distribute, modify, or create derivative works of any content from the app without our express written permission.
                        </Typography>
                        <Typography sx={title}>
                        6. User Conduct
                        </Typography>
                        <Typography>
                        You agree not to use the app in any manner that could damage, disable, overburden, or impair the app.
                        You agree not to attempt to gain unauthorized access to any part of the app, other users' accounts, or any systems or networks connected to the app.
                        </Typography>
                        <Typography sx={title}>
                        7. Termination
                        </Typography>
                        <Typography>
                        We reserve the right to terminate or suspend your access to the app at our sole discretion, without notice, if we believe you have violated these terms.
                        Upon termination, your right to use the app will cease immediately.
                        </Typography>
                        <Typography sx={title}>
                        8. Disclaimers and Limitation of Liability
                        </Typography>
                        The app is provided "as is" and "as available" without warranties of any kind, either express or implied.
                        We do not warrant that the app will be uninterrupted, error-free, or free from viruses or other harmful components.
                        In no event shall Xykal be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the app.
                        <Typography sx={title}>
                        9. Governing Law
                        </Typography>
                        <Typography>
                        These terms shall be governed by and construed in accordance with the laws of Quebec, without regard to its conflict of law principles.
                        Any disputes arising out of or relating to these terms or the use of the app shall be resolved exclusively in the courts of Quebec.
                        </Typography>
                        <Typography sx={title}>
                        10. Changes to Terms
                        </Typography>
                        <Typography>
                        We reserve the right to modify these terms at any time. Any changes will be effective immediately upon posting on the app.
                        Your continued use of the app after any changes signifies your acceptance of the revised terms.
                        </Typography>
                        <Typography sx={title}>
                        11. Contact Information
                        </Typography>
                        <Typography>
                        If you have any questions about these terms, please contact us at pleroux@skitsc.com.
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Footer type={'center'} {...props}/>
        </Box>
    )
}


export default Terms