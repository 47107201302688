

/*
 * Date: 2024
 * Description: Timesheet bar interaction ( for back button  or add a new punch for targeted timesheet)
 * Author: Philippe Leroux @ skitsc
 */


//Modules
import { ReactElement } from "react"
import { Grid , IconButton , Typography , Box } from "@mui/material"

//Interfaces
import { i_toptimebar_props } from "../../interfaces/timesheet.interface"

//Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const TopTimeBar = ( props : i_toptimebar_props ) : ReactElement => {
    return (
        <Grid container>
            <Grid item xs={12} p={2}>
                <Box sx={{ display : "flex"}}>
                <IconButton onClick={() => props.callback()}>
                    <ArrowBackIcon color={'primary'}/>
                </IconButton>
                    <Typography sx={{ marginLeft : '1vh' , fontSize : 22 , fontWeight : '800' , alignSelf : 'center'}}>{props.title}</Typography>
                </Box>
            </Grid>
        </Grid>
    )

}
export default TopTimeBar