

/*
 * Date: 2024
 * Description: Timesheet rows and actions related..
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { ReactElement } from "react"
import { Grid , Typography , Box , IconButton ,  } from "@mui/material"

//Interfaces && types
import { i_day_data, i_timesheet_week_props } from "../../../interfaces/timesheet.interface"

//Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';

import { header_style , header_text, row_style, row_text } from "../../../styles/tbl.styles";

const GenerateRows = ( props : i_timesheet_week_props ) : ReactElement => {

    const T = props.type === 'Admin'? 2.5 : 3
    return (
        <>
            {props.week_one !== null && props.week_two!== null &&
            <>
                <Grid container m={2}sx={{ border : "1px solid gray"}}>
                    <Grid item xs={T} sx={header_style}>
                        <Typography sx={[header_text , props.mobile && { fontSize : 17 }]}>Date</Typography>
                    </Grid>
                    <Grid item xs={T} sx={header_style}>
                        <Typography sx={[header_text , props.mobile && { fontSize : 17 }]}>In</Typography>
                    </Grid>
                    <Grid item xs={T} sx={header_style}>
                        <Typography sx={[header_text , props.mobile && { fontSize : 17 }]}>Lunch</Typography>
                    </Grid>
                    <Grid item xs={T}sx={header_style}>
                        <Typography sx={[header_text , props.mobile && { fontSize : 17 }]} >Break</Typography>
                    </Grid>
                    {props.type === 'Admin' &&
                        <Grid item xs={2} sx={{borderBottom : '1px solid gray'}}>
                            <Typography sx={[header_text , props.mobile && { fontSize : 17 }]} >Action</Typography>
                        </Grid>
                    }
                    <Grid item xs={12} sx={{ borderBottom : '1px solid gray'}}>
                        <Typography sx={[{ textAlign : 'center' , fontSize : 21 , fontWeight : 800 , padding:'10px' } , props.mobile && { fontSize : 15 }]}>Week 1 : { props.week_one.title }</Typography>
                    </Grid>
                    { props.week_one.days.map((day : i_day_data , index : number) => (
                        <Grid container key={index} sx={{ borderBottom : '1px solid gray'}}>
                            <Grid item xs={T} sx={row_style}> <Typography sx={[row_text , props.mobile && { fontSize : 15 }]}>{day.date} </Typography></Grid>
                            <Grid item xs={T} sx={row_style}> <Typography sx={[row_text , props.mobile && { fontSize : 15 }]}>{day.in_time}</Typography> </Grid>
                            <Grid item xs={T} sx={row_style}> <Typography sx={[row_text , props.mobile && { fontSize : 15 }]}>{day.lunch_time}</Typography> </Grid>
                            <Grid item xs={T} sx={row_style}> <Typography sx={[row_text , props.mobile && { fontSize : 15 }]}>{day.break_time}</Typography> </Grid>
                            {props.type === 'Admin' &&
                                <Grid item xs={2}>
                                    <IconButton onClick={() => props.callback('view', day.date)}>
                                        <VisibilityIcon sx={{ fontSize : 30}} color={'primary'} />
                                    </IconButton>
                                    <IconButton onClick={() => props.callback('add' , day.date , day)}>
                                        <AddIcon sx={{ fontSize : 30 , color : 'blue'}}  />
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>
                    ))}
                    <Grid item xs={12} sx={{ borderBottom : '1px solid gray' }}>
                        <Typography sx={[{ textAlign : 'center' , fontSize : 21 , fontWeight : 800, padding:'10px' } , props.mobile && { fontSize : 15 }]}> Week 2 : {props.week_two.title}</Typography>
                    </Grid>
                    { props.week_two.days.map((day : i_day_data , index : number) => (
                        <Grid container key={index} sx={{ borderBottom : '1px solid gray'}}>
                            <Grid item xs={T} sx={row_style}> <Typography sx={[row_text , props.mobile && { fontSize : 15 }]}>{day.date}</Typography> </Grid>
                            <Grid item xs={T} sx={row_style}> <Typography sx={[row_text , props.mobile && { fontSize : 15 }]}>{day.in_time}</Typography> </Grid>
                            <Grid item xs={T} sx={row_style}> <Typography sx={[row_text , props.mobile && { fontSize : 15 }]}>{day.lunch_time}</Typography> </Grid>
                            <Grid item xs={T} sx={row_style}> <Typography sx={[row_text , props.mobile && { fontSize : 15 }]}>{day.break_time}</Typography> </Grid>
                            {props.type === 'Admin' &&
                                <Grid item xs={2}>
                                    <IconButton onClick={() => props.callback('view' , day.date)}>
                                        <VisibilityIcon sx={{ fontSize : 30}} color={'primary'} />
                                    </IconButton>
                                    <IconButton onClick={() => props.callback('add' , day.date, day)}>
                                        <AddIcon sx={{ fontSize : 30 , color : 'blue'}}  />
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>
                    ))}

                     <Grid item xs={6}>
                        <Box sx={{ display : 'flex' , justifyContent : 'center'}}>
                            <IconButton onClick={() => props.callback('prev')}>
                                <ArrowBackIcon color={"primary"} sx={{ fontSize : 40}} />
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ display : 'flex' , justifyContent : 'center'}}>
                            <IconButton onClick={() => props.callback('next')}>
                                <ArrowForwardIcon color={"primary"} sx={{ fontSize : 40}} />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
              
                </>
            }
        </>
    )
}

export default GenerateRows;