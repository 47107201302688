import { t_mode } from "../types/types";

const s_format_modal_style = ( mode : t_mode , size : string ) => {
  const modal_style = {
      position: "fixed",
      top: "35%",
      left: "50%",
      marginTop : '6%',
      transform: "translate(-50%, -50%)",
      width: "95%",
      maxWidth: size === 'md' ? '800px' : '600px',
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      borderRadius: "6px",
      zIndex: 1001,
      minHeight:  size === 'sm' ? '200px' : '400px',
      backgroundColor: mode === 'Dark' ? '#222' : '#fff',
    };
    return modal_style;
}


export { s_format_modal_style }