


/*
 * Date: 2024
 * Description: Users punch rows , includes new actions and more..
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { Box , Grid , Typography , IconButton , Tooltip } from "@mui/material"
import { ReactElement } from "react"

//Interfaces && types
import { i_table_v2_row_props } from "../../../../interfaces/utility.interface";

//Icons
import PageviewIcon from '@mui/icons-material/Pageview';

//Utils
import { f_format_phone_cute , f_timestamp_to_date} from "../../../../utils/utility";

const UserPunchRow = ( props : i_table_v2_row_props) : ReactElement => {


    return (
        <Box sx={{ borderLeft : '1px solid #D3D3D3' , borderRight : '1px solid #D3D3D3' , borderBottom : '1px solid #D3D3D3'}}>
        <Grid container sx={{ padding : '6px'}}>
            <Grid item xs={props.headers[0].portion} sx={{ opacity : props.row.disabled ? 0.5 : 1}}>
                <Box sx={{ display : 'flex' , marginTop : '0.4vh' ,opacity : props.row.disabled ? 0.5 : 1 }}>
                    <Typography sx={{ fontSize : 14 }}>{f_timestamp_to_date(props.row.timestamp , 'long')}</Typography>
                </Box>
            </Grid>
            <Grid item xs={props.headers[1].portion} sx={{ alignSelf : 'center'}}>
                <Typography sx={{ alignSelf : 'center' , fontSize : 14 , opacity : props.row.disabled ? 0.5 : 1}}>{props.row.first_name + ' ' + props.row.last_name}</Typography>
            </Grid>
            <Grid item xs={props.headers[2].portion} sx={{ alignSelf : 'center' , display : 'flex'}}>
                <Typography sx={{ alignSelf : 'center' , fontSize : 14 , opacity : props.row.disabled ? 0.5 : 1}}>{props.row.email}</Typography>
            </Grid>
            <Grid item xs={props.headers[3].portion} sx={{ alignSelf : 'center' , opacity : props.row.disabled ? 0.5 : 1}}>
                {f_format_phone_cute(props.row.phone_one)}
            </Grid>
            <Grid item xs={props.headers[4].portion} sx={{ alignSelf : 'center' , opacity : props.row.disabled ? 0.5 : 1}}>
             last punch here
            </Grid>
            <Grid item xs={props.headers[5].portion} sx={{ alignSelf : 'center' }}>
                <Tooltip title={ props.row.type === 'Admin' ? "Admin does not have timesheets" : "Select this user !"}>
                    <IconButton sx={{}} onClick={() => props.callback(props.row , 'table')}><PageviewIcon color={'primary'}/></IconButton>
                </Tooltip>
            </Grid>
          
        </Grid>
    </Box>
    )
}

export default UserPunchRow