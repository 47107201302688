/*
 * Date: 2024
 * Description: Component for updating password of the current user
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { FC , ChangeEvent , useState , Dispatch , SetStateAction , useContext } from 'react';
import { TextField , Tooltip , IconButton , Grid , Typography } from '@mui/material';

//Interfaces && types
import { i_profile_password_item } from '../../interfaces/user.interface';
import { LoadingButton } from '@mui/lab';

//icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

//Styles
import { input_base, sub_title , bottom_line } from '../../styles/form.styles';

//Context
import { MainContext } from '../../context/context';

const ProfilePasswordItem : FC<i_profile_password_item> = ({ form , setForm , callback , errors , loading , selected , mobile}) => {
    const S = setForm;
    const V = form;
    const E = errors;
    const { user } = useContext(MainContext)
    const [ vis_one , setVisOne ] = useState<boolean>(true);
    const [ vis_two, setVisTwo ] = useState<boolean>(true);
    const [ vis_three, setVisThree ] = useState<boolean>(true);
    const Visibility = ({setOn , visibility } : { setOn: Dispatch<SetStateAction<boolean>>; visibility: boolean }) => {
        return (
            <Tooltip title={visibility ? 'Show' : 'Hide'}>
                <IconButton onClick={() => setOn((prevVisibility) => !prevVisibility)}>
                    {visibility? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
            </Tooltip>
        )
    }
    const handleValue = (e : ChangeEvent<HTMLInputElement>) => { 
        var clean_value = e.target.name.split('-profile')
        S({
           ...V,
            [clean_value[0]] : e.target.value
        })
    }
    const T = user.type
    const M = mobile
    return (
        <Grid  container mt={6}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography sx={{ fontWeight : 800 , fontSize : 20 ,  alignSelf : 'center'}}>Auth management</Typography>
                    <Typography sx={{fontWeight : 800 , fontSize : 16 , marginBottom : '2vh' , color : 'grey'}}>{T === 'User' ? "Manage your nip" : "Manage your password"} </Typography>
                </Grid>
                <Grid item xs={ M ? 4 : 3}>
                    <Typography sx={sub_title} >{T === 'User' ? "Current nip" : "Current password :"} </Typography>
                </Grid>
                <Grid item xs={M ? 8 : 9}>
                    <TextField size={'small'} name={T === 'User' ? "old_nip-profile" : "old_password-profile"}  autoComplete="new-password" type={vis_one ? 'password' : 'text'}  sx={[input_base , { width :  M ? '225px' : '350px'} ]}  
                    error={T === 'User' ? E.old_nip !== '' && true  :E.old_password !== '' && true } helperText={T === 'User' ? E.old_nip :E.old_password} value={T === 'User' ? V.old_nip :V.old_password}
                     onChange={handleValue} 
                    InputProps={{ endAdornment: <Visibility setOn={setVisOne} visibility={vis_one} />}} />
                </Grid>
                <Grid item xs={ M ? 4 : 3}>
                    <Typography sx={sub_title} >{T === 'User' ? "New nip" : "New password :"} </Typography>
                </Grid>
                <Grid item xs={M ? 8 : 9}>
                    <TextField size={'small'} name={T === 'User' ? "nip-profile" : "password-profile"} autoComplete="new-password" type={vis_two ? 'password' : 'text'} sx={[input_base , { width :  M ? '225px' : '350px'} ]}
                     error={T === 'User' ? E.nip !== '' && true  : E.password !== '' && true } helperText={T === 'User' ? E.nip : E.password } value={T === 'User' ? V.nip :V.password} 
                    onChange={handleValue}
                    InputProps={{ endAdornment: <Visibility setOn={setVisTwo} visibility={vis_two} />}}/>   
                </Grid>
                <Grid item xs={ M ? 4 : 3}>
                    <Typography sx={sub_title} >{T === 'User' ? "Confirm Nip" : "Confirm password :"} </Typography>
                </Grid>
                <Grid item xs={M ? 8 : 9}>
                    <TextField size={'small'} name={T === 'User' ? "confirm_nip-profile" : "confirm_password-profile"} autoComplete="new-password" type={vis_three ? 'password' : 'text'}  sx={[input_base, { width :  M ? '225px' : '350px'} ]}
                    error={T === 'User' ? E.confirm_nip !== '' && true  : E.confirm_password !== '' && true} helperText={T === 'User' ? E.confirm_nip : E.confirm_password } value={T === 'User' ? V.confirm_nip :V.confirm_password}
                    onChange={handleValue}
                    InputProps={{ endAdornment: <Visibility setOn={setVisThree} visibility={vis_three} />}}/>   
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={12}> 
                    <LoadingButton loading={loading && selected === 'password' ? true : false} onClick={() => callback(T === 'User' ? 'nip' : 'password', V)} variant="contained" color="primary" sx={{ display : 'block' , marginLeft : 'auto' , marginRight : 'auto' , width:'300px' }}>{ T === 'User' ? "Change your nip" : "Change your password"}</LoadingButton>
                </Grid>
           
        </Grid>
    );
  };

  export default ProfilePasswordItem;