/*
 * Date: 2024
 * Description: Private Timesheet page selecte user then -> timesheet -> according punches and CRUD + view
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { ReactElement , useState , useContext , useEffect , ChangeEvent } from "react";
import { Box , Typography , Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";


//Interfaces && types
import { i_snack_alert , i_search_filter , i_socket_response , i_pagination_new , i_alert_props , i_tbl_header , i_table , i_basic_search_props, i_top_tbl_bar, i_initial_props, i_shell_modal, i_modal_body_view, i_prompt_body } from "../interfaces/utility.interface";
import { i_user } from "../interfaces/user.interface";
import { i_punch , i_timesheet_complete, i_timesheet_filter_admin, i_timesheet_table_props, i_toptimebar_props , i_punch_display_props, i_punch_form, i_punch_errors } from "../interfaces/timesheet.interface";

//Context
import { SocketContext } from "../context/socket.context";
import { MainContext, ThemeContext } from "../context/context";

//Components
import Footer from "../components/utility/footer";
import CircularUnderLoad from "../components/utility/center.loader";
import AlertDialog from "../components/utility/alert";
import Tbl from "../components/table/table";
import TopTableBar from "../components/utility/top.table.bar";
import SearchFilter from "../components/utility/inputs/search.filter";
import ShellModal from "../components/modal/modal";
import ModalBodyImgView from "../components/modal/body/modal.view";
import TimeSheetDisplay from "../components/punch/table/timesheet.display";
import TopTimeBar from "../components/punch/timesheet.bar";
import PunchDisplay from "../components/punch/punch.display";
import PromptBody from "../components/modal/utils/modal.prompt";
import PunchForm from "../components/punch/form/punch.form";

//Utilities
import { f_fetch } from "../api/fetch";
import { CheckEnv, f_encode_query_data , f_timestamp_to_date, getPayAllPeriods , getPayPeriodFromDate } from "../utils/utility";

//Constants 
import { default_filter , default_punch, default_punch_errors, default_timesheet_complete, empty_promise } from "../utils/constant";
import { t_display, t_method } from "../types/types";

//Styles
import { tbl_boxing , header_row } from "../styles/tbl.styles";
import { m_validate_punch } from "../validation/main.middleware";


const Timesheets = ( props : i_initial_props ) : ReactElement => {

    const initial = () => {
        const periods = getPayAllPeriods(new Date().getFullYear(), true);
        const period = getPayPeriodFromDate(periods, new Date().valueOf());
        return { period : period? period.period : 0 , user_id : '' }
    }

    const socket = useContext(SocketContext)
    const { mode } = useContext(ThemeContext)
    const dev = CheckEnv();
    const [ display , setDisplay ] = useState<t_display>('form')
    const [ modal_display, setModalDisplay ] = useState<t_display>('default')
    const [ search, setSearch ] = useState<string>('')
    const [ users , setUsers ] = useState<i_user[]>([])
    const [ open , setOpen ] = useState<boolean>(false)

    const [ user_count, setUsersCount ] = useState<number>(0)
    const { HandleLogout , user } = useContext(MainContext);
    const [ data , setData ] = useState<i_timesheet_complete>(default_timesheet_complete)
    const [ day , setDay ] = useState<string>('')
    const [ target , setTarget ] = useState<i_punch>(default_punch)
    const [ punch_errors , setPunchErrors ] = useState<i_punch_errors>(default_punch_errors)
    const [ day_punch, setDayPunch ] = useState<i_punch[]>([])
    const [ loading, setLoading ] = useState<boolean>(false)
    const [ filter , setFilter ]  = useState<i_search_filter>({...default_filter })
    const [ timesheet_filter , setTimesheetFilter ] = useState<i_timesheet_filter_admin>(initial())
    const [ api_error , setApiError ] = useState<i_snack_alert>({open : false , promise : empty_promise});
    const nav = useNavigate()

    useEffect(() => {
        const findUser = ( id : string ) : boolean => {
            const user = users.find( ( item : i_user ) => item._id === id )
            return user? true : false
        }
        socket.removeAllListeners("punch");
        socket.on('punch', ( output : i_socket_response ) => {
            const found : boolean = findUser(output.item.user_id)
            if(found){
                const data_to_update = [ ...data.punches ] 
                const day_data_to_update = [ ...day_punch ] 
                if(output.type === 'Update'){
                    const updateItem = ( ItemUpdated : i_punch ) => {
                        const updatedItems = data_to_update.map( ( item : i_punch , i : number) => {
                            if (item._id === ItemUpdated._id) {
                                return ItemUpdated
                            } else {
                                return item
                            }
                        })
                        const updatedPunchItem = day_data_to_update.map( ( item : i_punch , i : number ) => {
                            if (item._id === ItemUpdated._id) {
                                return ItemUpdated
                            } else {
                                return item
                            }
                        })
                        setDayPunch(updatedPunchItem)
                        setData({...data , punches : updatedItems})
                    } 
                    updateItem(output.item)
                }
                if(output.type === 'Add'){
                    const addRow = ( ItemAdded : i_punch ) => {
                        const objectExists = data_to_update.some(( obj : i_punch ) => obj._id === ItemAdded._id);
                        const day_obj_exists = day_data_to_update.some(( obj : i_punch ) => obj._id === ItemAdded._id);
                        if(!day_obj_exists && display !== 'table'){
                            data_to_update.push(ItemAdded)
                            setDayPunch(day_data_to_update)
                        }
                        if(!objectExists){
                            data_to_update.push(ItemAdded)
                            setData({...data , punches : data_to_update}) 
                        }
                    } 
                    addRow(output.item)
                }
                if(output.type === 'Delete'){
                    const deleteRow = ( ItemDeleted : i_punch ) => {
                        const updatedItems = data_to_update.filter( ( item : i_punch ) => item._id!== ItemDeleted._id)
                        const updateDayItems = day_data_to_update.filter( ( item : i_punch ) => item._id !== ItemDeleted._id)
                        setData({...data , punches : updatedItems})
                        setDayPunch(updateDayItems)
                    } 
                    deleteRow(output.item)
                }
            }
        })
      },[socket , data , day_punch , users , display])
    useEffect(() => {
        const getUsers = async() => {
            const params = f_encode_query_data(filter)
            const res = await f_fetch('/users/filtered?'+ params , 'GET' , true , null)
            if(res.type === 'Success'){
                setUsers(res.data.users)
                setUsersCount(res.data.count)
            }else{
                if(res.type === 'Unauthorized'){
                    HandleLogout(nav)
                } else{
                    setApiError({open : true, promise : res.data})
                }
            }
        }
        getUsers()
    },[nav , HandleLogout , filter])
    useEffect(() => {
        const getTimesheets = async() => {
            setLoading(true)
            const res = await f_fetch('/timesheet/user/'+timesheet_filter.period+'/'+timesheet_filter.user_id , 'GET' , true , null)
            if(res.type === 'Success'){
                const complete_timesheet : i_timesheet_complete = { ...res.data.timesheet, punches : res.data.punches}
                setData(complete_timesheet)
            }else{
                if(res.type === 'Unauthorized') HandleLogout(nav)
            }
            setLoading(false)
        }
        if(timesheet_filter.user_id !== '') getTimesheets()
       
    },[nav , HandleLogout , timesheet_filter])
    const handleSearch = (e :ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setSearch(value)
    }
    const handleRow = ( value : i_user , type : t_display ) => {
        setTimesheetFilter({...timesheet_filter, user_id : value._id })
        setDisplay(type)
    }
    const handleBack = () => {
        if(display === 'view'){
            setDisplay('table')
            setDayPunch([])
        }else{
            setDisplay('form')
            setTimesheetFilter({...timesheet_filter, user_id : '' })
        }
    }
    const handleRowsPerPage = async( value : number) => {
        const new_filter : i_search_filter = { ...filter}
        new_filter.rows_per_page = value
        new_filter.page = 1
        setFilter(new_filter)
    }
    const pagination_props : i_pagination_new = {
        filter : filter,
        onPageChange: (event: React.ChangeEvent<unknown> , page : number) => {
            const new_filter = { ...filter }
            new_filter.page = page
            setFilter(new_filter)
        },
        count : user_count,
        handleRowsPerPage : handleRowsPerPage,
        title : 'Users'
    }
    const user_headers : i_tbl_header[] = [
        { value : "Date added" , css : { ...header_row, } , portion : 2 , },
        { value : "Name" , css : { ...header_row } , portion : 3 },
        { value : "Email" , css : { ...header_row } , portion : 2 },
        { value : "Phone" , css : { ...header_row} , portion : 2},
        { value : "Latest punch" , css : { ...header_row} , portion : 2},
        { value : 'Action', css : header_row, portion :1  }
    ]
    const targetTimestamp = ( dateString: string, period: 'in' | 'out'): number => {
        const time = period === 'in' ? '08:00:00' : '12:00:00';
        const date = new Date(`${dateString}T${time}`);
        const unixTimestamp = Math.floor(date.getTime() / 1000);
        return unixTimestamp;
    }
    const handlePunch = ( view :  'prev' | 'next' | 'view' | 'add' , item : string | any ) => {
        if(view === 'view'){
            setDisplay(view)
            setDay(item)
            const new_punch_arr: i_punch[] = [];
            for (let x = 0; x < data.punches.length; x++) {
                const punchDate = new Date(data.punches[x].punch_in * 1000).toISOString().split('T')[0];
                if (punchDate === item)   new_punch_arr.push(data.punches[x]);
            }
            if (new_punch_arr.length > 0) {
                setDayPunch(new_punch_arr.reverse());
            } else {
                console.log("No punches for that today...");
            }
        } else if(view === 'add'){
            const clean_punch : i_punch = { ...default_punch}
            clean_punch.timesheet_id = item.timesheet_id
            clean_punch.user_id = item.user_id
            if(item.punches.length > 0) {
                clean_punch.punch_in = item.punches[0].punch_out;
                clean_punch.punch_out = Number(item.punches[0].punch_out + 3600);
            }else{
                clean_punch.punch_in = targetTimestamp(item.date , 'in')
                clean_punch.punch_out = targetTimestamp(item.date , 'out')
            }
            setTarget(clean_punch)
            setModalDisplay('form')
            setOpen(true)
        }
    }
    const handlePunchAction = ( action : t_display , row : i_punch ) => {
        setModalDisplay(action)
        setOpen(true)
        setTarget(row)
    }
    const handleSubmit = async() => {
        setLoading(true)
        const [ valid , message , field ] = m_validate_punch(target)
        if(valid){
            const method : t_method = target._id === '' ? 'POST' : 'PUT'
            const res = await f_fetch('/timesheet/punch/manual/' , method , true , target)
            if(res.type === 'Success'){
                //Socket will update this
                setOpen(false)
                setModalDisplay('view')
                setTarget({...default_punch})
            }else{
                if(res.type === 'Unauthorized') HandleLogout(nav)
                else setApiError({open : true, promise : res.data})
            }
        }else  setPunchErrors({...punch_errors, [field] : message})
        setLoading(false)
    }
    const handleDelete = async() => {
        setLoading(true)
        const res = await f_fetch('/punch/'+target._id , 'DELETE' , true , null)
        if(res.type === 'Success'){
            //Socket will update this
        }else{
            if(res.type === 'Unauthorized') HandleLogout(nav)
            else setApiError({open : true, promise : res.data})
        }
        setLoading(false)
        setOpen(false)
        setModalDisplay('view')
    }
    const table_v2_props : i_table = {
        data : users,
        title : 'Select a user first',
        loading : loading,
        headers : user_headers,
        callback : handleRow,
        setApiError : setApiError,
        row_model : "user_punch",
        pagination : pagination_props
    }
    const search_input_props : i_basic_search_props = {
        search : search,
        handleSearch : handleSearch,
        placeholder : 'Search',
        loading : loading,
        callback : () => setFilter({...filter, search : search}),
        error_msg : '',
        count : user_count
    }
    const table_tool_bar_props : i_top_tbl_bar = {
        title : 'Select a user to proceed',
        callback : () => {},
        inputs : [<SearchFilter {...search_input_props} />],
        count : user_count,
        add : false,
    }
    const alert_props : i_alert_props = {
        event : api_error,
        handleClose : () => setApiError({ open : false, promise : empty_promise }),
        type : 'simple',
        mobile : props.mobile
    }
    const timesheet_props : i_timesheet_table_props = {
        loading : loading,
        data : data,
        filter : timesheet_filter,
        setFilter : setTimesheetFilter,
        type : user.type,
        callback : handlePunch,
        mobile : props.mobile
    }
    const punch_form_props : i_punch_form = {
        form : target,
        setForm : setTarget,
        loading : loading,
        errors : punch_errors,
        filter : timesheet_filter,
        users : users,
        callback : handleSubmit,
        mode : mode,
        dev : dev,
    }
    const formatUser = ( ) : string => {
        const user : i_user | undefined = users.find( ( user : i_user ) => user._id === timesheet_filter.user_id)
        const day_targeted = display === 'view' ? day + ' punchs actions' : 'sheets'
        if(user){
            return user.first_name + ' ' + user.last_name + ' ' + day_targeted
        }else{
            return ''
        }
    }
    const timebar_props : i_toptimebar_props = {
        title : formatUser(),
        display : display,
        callback : handleBack
    }
    const punch_display_props : i_punch_display_props = {
        data : day_punch,
        type : user.type,
        callback : handlePunchAction,
        mobile : props.mobile
    }
    const view_props : i_modal_body_view = {
        path : Array.isArray(target.path) ? target.path : [target.path],
        prepath : process.env.REACT_APP_API_URL + '/api/uploads/punch/',
        title : f_timestamp_to_date(target.punch_in, 'long') + ' - ' + target.type + ' - ' + (target.punch_out > 0 ? f_timestamp_to_date(target.punch_out , 'long') : ' running..'),
        alt : 'Punch image',
    }
    const prompt_body_props : i_prompt_body = {
        title : 'Are you sure you want to delete this punch?',
        handleClose : () => setOpen(false),
        callback : handleDelete,
        content : f_timestamp_to_date(target.punch_in, 'long') + ' - ' + target.type + ' - ' + (target.punch_out > 0 ? f_timestamp_to_date(target.punch_out , 'long') : ' running..'),
    }
    const shell_modal_props : i_shell_modal = {
        open : open,
        handleClose : () => setOpen(false),
        title : modal_display === 'view' ? 'View punch screenshot' : modal_display === 'disable' ? 'Delete entry' : modal_display === "form" && target._id !== '' ? 'Edit punch' : 'Add punch',
        children : modal_display === 'view'? <ModalBodyImgView {...view_props} /> : modal_display === 'disable' ? <PromptBody {...prompt_body_props} /> :<PunchForm {...punch_form_props} /> ,
        mode : mode,
        display : modal_display,
        
    }
    return (
        <Box sx={{ }}>
            <Box sx={{ minHeight : '91vh'}}>
                { loading ? <CircularUnderLoad type={"full"} /> :
                    <Box>
                        {display === 'form' && 
                            <>
                                <Box sx={{ display : 'flex' , justifyContent : "center" , paddingTop : '1vh'}}><Typography variant={'h4'}>Timesheets</Typography></Box>
                                    <Box sx={{ padding: '24px'}}>
                                        <TopTableBar {...table_tool_bar_props}/>
                                        <Box sx={tbl_boxing}>
                                            <Tbl {...table_v2_props} />
                                        </Box>
                                </Box>
                            </>
                        }
                        { timesheet_filter.user_id !== '' && display === 'table' &&
                            <Box>
                                <TopTimeBar {...timebar_props } />
                                <TimeSheetDisplay {...timesheet_props}  />
                            </Box>
                        }
                        { timesheet_filter.user_id !== '' && display === 'view' &&
                            <Box>
                                <TopTimeBar {...timebar_props } />
                                {day_punch.length > 0 ?
                                    <PunchDisplay {...punch_display_props} />
                                    : 
                                    <Box sx={{ display : 'flex' , justifyContent : 'center' , padding : '12px'}}>
                                        <Typography sx={{ fontSize : 22 , fontWeight : 800}}>No punch for this day..</Typography>
                                    </Box>
                                }
                            </Box>
                        }
                    </Box>
                }
                <AlertDialog {...alert_props}/>
            </Box>
            <Modal open={open} onClose={() => setOpen(false)} children={<ShellModal {...shell_modal_props} />} />
            <Footer type={'center'} {...props}/>
        </Box>
    )
}


export default Timesheets;