

/*
 * Date: 2024
 * Description: Time components that display current punch time or live time
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { useEffect , FC  } from "react";
import { Box , Typography  } from "@mui/material";

//Interfaces
import { i_current_time } from "../../interfaces/utility.interface";

//Utilitys
import { CurrentDate , formatElapsedTime } from "../../utils/utility";


const TimeDisplay : FC<i_current_time> = (props : i_current_time) => {

    useEffect(() => {
        const interval = 1000
        const updateTime = () => {
            const now = new Date();
            if(props.timestamp) {
            const elapsedSeconds = Math.floor((now.getTime() - props.timestamp * 1000) / 1000);
                props.setTime(formatElapsedTime(elapsedSeconds));
            } else {
                props.setTime(now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' , second: '2-digit' }));
            }
        };
        updateTime();
        const intervalId = setInterval(updateTime, interval);
        return () => clearInterval(intervalId);
    }, [props.timestamp]);
    return (
        <Box>
            <Typography sx={{ fontSize : 22 , textAlign : 'center' , marginBottom : '1vh' , fontWeight : 800}}>{CurrentDate()}</Typography>
            <Box sx={{ display : 'flex' , justifyContent : 'center' , fontWeight : '800' , fontSize : 20 , marginBottom : '1vh'}}>
                {props.time}
            </Box>
        </Box>
    )
};

export default TimeDisplay;