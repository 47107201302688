

/*
 * Date: 2024
 * Description: Timesheet display table , show current timesheet integrates controls for next and previous timesheet
 * Author: Philippe Leroux @ skitsc
 */


//Modules
import { ReactElement, useEffect, useState } from "react";
import { Box , Grid , Typography } from "@mui/material";

//Components
import GenerateRows from "./timesheet.rows";

//Interfaces
import { i_timesheet_table_props , i_punch , i_timesheet_complete , i_day_complete_data , i_day_data, i_timesheet_week_props } from "../../../interfaces/timesheet.interface";
import { i_period_data } from "../../../interfaces/utility.interface";

//Utilitys
import { getPayAllPeriods , millisecondsToTime } from "../../../utils/utility";

const TimeSheetDisplay = ( props : i_timesheet_table_props ) : ReactElement => {
    const [ week_one, setWeekOne ] = useState<i_day_complete_data | null>(null)
    const [ week_two, setWeekTwo ] = useState<i_day_complete_data | null>(null)

    useEffect(() => {
        const getPeriodValue = ( periods : i_period_data[] , target : number ) : i_period_data | null => {
            for(var x : number = 0; x < periods.length; x++) {
                if(periods[x].period === target) {
                    return periods[x]
                }
            }
            return null;
        }
        
        const calculateElapsedTime = (punch: i_punch): number => {
            if (punch.punch_out === 0) return new Date().valueOf() - punch.punch_in * 1000;
            return (punch.punch_out - punch.punch_in) * 1000;
        };
        
        const getDayData = (punches: i_punch[], date: string): i_day_data => {
            const dayPunches = punches.filter(punch => {
                const punchDate = new Date(punch.punch_in * 1000).toISOString().split('T')[0];
                return punchDate === date;
            });
        
            let totalElapsedTime = 0;
            let lunchElapsedTime = 0;
            let breakElapsedTime = 0;
        
            dayPunches.forEach(punch => {
                const elapsedTime = calculateElapsedTime(punch);
                if (punch.type === "in") {
                    totalElapsedTime += elapsedTime;
                } else if (punch.type === "lunch") {
                    lunchElapsedTime += elapsedTime;
                } else if (punch.type === "break") {
                    breakElapsedTime += elapsedTime;
                }
            });
        
            return {
                date,
                punches: dayPunches,
                in_time: millisecondsToTime(totalElapsedTime),
                lunch_time: millisecondsToTime(lunchElapsedTime),
                break_time: millisecondsToTime(breakElapsedTime)
            };
        };
        
        const getPeriodPunches = (timesheet: i_timesheet_complete): { week_one: { days: i_day_data[] , title : string}, week_two: { days: i_day_data[] , title : string} } | null => {
            const { punches } = timesheet;
            const periods = getPayAllPeriods(2024, true);
            const period = getPeriodValue(periods, timesheet.pay_period);
        
            if (period !== null) {
                const startDate = new Date(period.monday);
                const endDate = new Date(period.sunday);
                const dayDataArray: i_day_data[] = [];
        
                for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
                    const currentDate = new Date(d).toISOString().split('T')[0];
                    const dayData = getDayData(punches, currentDate);
                    dayDataArray.push(dayData);
                }
                if(dayDataArray.length === 14) {
                const week_one_days = dayDataArray.slice(0, 7);
                const week_two_days = dayDataArray.slice(7, 14);
        
                return {
                    week_one: {
                        days: week_one_days,
                        title :week_one_days[0].date + " to " + week_one_days[6].date
                    },
                    week_two: {
                        days: week_two_days,
                        title :  week_two_days[0].date + " to " + week_two_days[6].date
                    }
                };
                }
            }
        
            return null;
        };
        const days_data = getPeriodPunches(props.data);
        if(days_data !== null) {
            setWeekOne(days_data.week_one)
            setWeekTwo(days_data.week_two)
        }

    },[props.data])

    const handleClick = ( value : 'prev' | 'next' | 'view' | 'add', date? : string , row? : any) : void => {
        if(value === 'view') date !== undefined && props.callback !== undefined && props.callback(value , date)
        if(value === 'add'){
            const clean_row = {...row}
            clean_row.timesheet_id = props.data._id
            clean_row.user_id = props.data.user_id
            props.callback !== undefined && props.callback(value , clean_row)
        }
        if(value === 'prev' || value === 'next') {
            var new_period = props.data.pay_period + (value === 'prev'? -1 : 1)
            if(Number(new_period) > 0 && new_period < 27)  props.setFilter({ ...props.filter, period : new_period }) 
        }
    }

    const timesheet_weeks_props : i_timesheet_week_props = {
        week_one : week_one,
        week_two : week_two,
        type : props.type,
        callback : handleClick,
        mobile : props.mobile
    }
    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    <Typography sx={{ textAlign : 'center' , fontSize : 22 , fontWeight : 800 , marginBottom : '1vh'}}>Timesheet for period { props.data?.pay_period  } </Typography>
                </Grid>
                <GenerateRows {...timesheet_weeks_props} />
            </Grid>
        </Box>
    )
}



export default TimeSheetDisplay;