

/*
 * Date: 2024
 * Description: Reusable search input
 * Author: Philippe Leroux @ Skitsc
 */


//Modules
import { Box , TextField } from "@mui/material"
import { ReactElement, ChangeEvent} from "react"
import { LoadingButton } from "@mui/lab"
//interfaces && types
import { i_basic_search_props } from "../../../interfaces/utility.interface"

const SearchFilter = ( props : i_basic_search_props ) : ReactElement => {
    return (
        <Box sx={{ display : 'flex' , paddingLeft : '10%' ,}}>
            <TextField size="small" value={props.search} placeholder={props.placeholder} error={props.error_msg !== '' ? true : false} helperText={props.error_msg} onChange={(e :ChangeEvent<HTMLInputElement >) => props.handleSearch(e)}
             />    
            <LoadingButton sx={{ padding : '2px'}} variant="contained" loading={props.loading} disabled={props.loading} onClick={() => props.callback()}>Search</LoadingButton>
        </Box>
    )
}

export default SearchFilter