  /*
 * Date: 2024
 * Description: Styles for forms
 * Author: Philippe Leroux @SKITSC
 */

const upload_box_style = {display: 'flex', flexWrap: 'wrap', gap: '1em' , justifyContent : 'center' }

const input_base = { pl : 0, minHeight: '60px', borderRadius: 10, paddingRight : 2}

const button_center = { display : 'block', marginLeft : 'auto',  marginRight : 'auto',fontWeight : 800 }

const sub_title = { fontWeight : 600 }
 
const bottom_line = { borderColor : '#EAECF0' , opacity : 0.4 }

const top_table_bar_style = { paddingLeft : '12px' , alignSelf : 'center' , fontWeight : 600 , paddingRight : '6px' }

export { input_base , button_center , sub_title , bottom_line , top_table_bar_style , upload_box_style}