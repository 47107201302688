/*
 * Date: 2024
 * Description: profile row for profile data display..
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { FC , ChangeEvent } from 'react';
import { TextField, MenuItem } from '@mui/material';
//Interfaces && types
import { i_profile_item } from '../../interfaces/user.interface';

import { provinces } from '../../utils/constant';

//Styles..
import { input_base } from '../../styles/main.styles';

const ProfileItem : FC<i_profile_item> = ({ title, value , setValue , error , width , select , mobile}) => {
    return (
        <TextField  select={select !== undefined ? true : false} name={title + '-profile'} size={'small'} placeholder='...' 
        sx={[input_base , width !== undefined ? { width : width } : { width : '350px'}]}  error={error !== "" && true} 
            helperText={error} value={value} onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e)} autoComplete="new-password">
                    {select !== undefined && title === 'state' &&   
                    provinces.map((provinces , index ) => (
                            <MenuItem key={index} value={provinces.value}>{provinces.value}</MenuItem>
                        )) }
        </TextField>
    );
  };

  export default ProfileItem;