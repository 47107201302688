/*
 * Date: 2024
 * Description: login page !
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { useState, useEffect, useContext , ReactElement , ChangeEvent } from "react";
import { Box ,TextField, IconButton, Typography , Checkbox } from "@mui/material";
import { LoadingButton } from '@mui/lab';

//Contexts
import { MainContext, ThemeContext } from "../context/context";
import { SocketContext } from "../context/socket.context";

//Middlewares
import { m_validate_admin_login } from "../validation/main.middleware";
import { m_validate_email , m_validate_password } from "../validation/utility.middleware";

//Api
import { f_fetch } from "../api/fetch";

//Icons And button
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

//Styles
import { FormStyles } from "../styles/main.styles"

//Component
import Alert from "../components/utility/alert";

//Interfaces && Types
import { i_admin_login } from "../interfaces/user.interface";
import { i_alert_props, i_initial_props } from "../interfaces/utility.interface";
import { i_snack_alert } from "../interfaces/utility.interface";
//Icons
import { empty_promise } from "../utils/constant";

//Utilities
import { f_get_local_key , f_set_local_key , f_kill_storage } from "../utils/utility";

const Login = ( props : i_initial_props ) : ReactElement  => {

    //Contexts
    const { HandleLogin , config } = useContext(MainContext);
    const { mode } = useContext(ThemeContext);
    const socket = useContext(SocketContext)
    //Main states
    const [ display , setDisplay ] = useState<'login' | 'recovery' | 'final' | 'reset' | 'register'>('login')
    const [ loading , setLoading ] = useState<boolean>(false);
    const [ api_errors , setApiErrors ] = useState<i_snack_alert>({ open: false, promise: empty_promise });
    const [ store , setStore ] = useState<boolean>(false);
    //Form
    const [ email, setEmail ] = useState<string>("");
    const [ password, setPassword] = useState<string>("");
    const [ visible, setVisible ] = useState<boolean>(false);
    //Errors  
    const [ Eemail , setEemail ] = useState<string>("");
    const [ Epassword , setEpassword ] = useState<string>("");
    
    const handleOnSubmit = async(e : any) => {
        setLoading(true);
        e.preventDefault();
        const clean_login : i_admin_login = { email, password }
        const [ valid , error , field ] = m_validate_admin_login(clean_login , display)
        if(valid){
            var endpoint = display === 'login' ? '/login' : display === 'register' ? '/register' : '/recovery';
            const res = await f_fetch(endpoint, 'POST' , true , clean_login);
            if(res.type === "Success"){
                if(display === 'recovery') setDisplay('final')
                if(res.data._id !== undefined) {
                    HandleLogin(res.data);
                    socket.emit('join', { roomId : res.data.type , user_id : res.data._id , socket_id : socket.id })
                }
            }
            setApiErrors({ open: true, promise : res });
        }else{
            if(field === 'email') setEemail(error);
            if(field === "password")  setEpassword(error);
        }
        setLoading(false);
    };
    useEffect(() => {
        if(Eemail!== '' && m_validate_email(email)) setEemail('')
        if(Epassword!== '' && m_validate_password(password)) setEpassword('')
    }, [Eemail , email ,Epassword , password]);
    useEffect(() => {
        var value = f_get_local_key('login_email')
        if(value!== null) {
            setEmail(value)
            setStore(true)
        }
    },[])
    useEffect(() => {
        if(Eemail !== '') setEemail('')
        if(Epassword!== '') setEpassword('')
        if(display !== 'login'){
            setEmail('')
            setPassword('')
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps 
    },[display])

    const handleStore = ( value : boolean ) => {
        if(value){
            f_set_local_key('login_email', email , 2592000000)
            setStore(true)
        }else{
            f_kill_storage('login_email')
            setStore(false)
        }
    }

    const alert_props : i_alert_props = {
        event : api_errors,
        handleClose : () => setApiErrors({ open : false, promise : empty_promise }),
        type : 'full',
        mobile : props.mobile
    }

    useEffect(() => {  
        const handleKeyPress = (event : any) => {
            if (event.key === 'Enter' && !loading && display!== 'final')  handleOnSubmit(event);
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    //eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [loading , email , password]);
  const pre_path = process.env.REACT_APP_API_URL + '/api/web/public/config/'
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" , backgroundImage : mode === "Dark" ? "url('./images/dark-bg-login.jpg')" :"url('./images/bg-login.jpg')" ,backgroundSize: "cover",  backgroundPosition: "center"}}>
        <Box sx={{ width : '400px',borderRadius: 1 , padding: '2em'}}>
            <Box>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", pb: 2}}>
                    <Box component={'img'} alt="Garage logo" sx={{ width: '150px' , marginBottom : '1vh'}} src={ config.path !== '' ? pre_path + config.path : './img/placeholder.png'}></Box>
                    <Typography sx={{ fontWeight : '600' , marginBottom : '1vh'}} component="h1" variant="h5">Log in to your account</Typography>
                    <Typography sx={{ fontSize : 14 , color : 'gray'}} component="h2" variant="h6"> "Welcome back! Please enter your details</Typography>
                </Box>
                <Box component={'form'}>
                    <Typography sx={{ fontWeight : 600}}>Email</Typography>
                        <TextField  fullWidth  size="small" placeholder="Enter your email address" sx={FormStyles.textfield} autoComplete="new-password"
                        value={email} helperText={Eemail} error={Eemail !== "" ? true : false}
                        InputLabelProps={{ shrink: true }} 
                        onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setEmail(e.target.value)}/>
                    <Typography sx={{ fontWeight : 600}}>Password</Typography>
                    <TextField fullWidth  size="small" type={visible ? "text" : "password"} placeholder="Enter your password" sx={FormStyles.textfield} autoComplete="new-password"
                        value={password} helperText={Epassword} error={Epassword !== "" ? true : false}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setPassword(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ endAdornment: <IconButton edge="end" aria-label="toggle password visibility" 
                        onClick={() => setVisible(!visible)}>{visible ? <VisibilityOffIcon /> : <VisibilityIcon />}</IconButton>}}/>
                        <Box sx={{ display : 'flex' , marginBottom : '1vh'}}>
                            <Checkbox disabled={email.length === 0 ? true : false} checked={store} onChange={(e) => handleStore(e.target.checked)} sx={{ marginLeft : '-10px'}}/>
                            <Typography sx={{ alignSelf : 'center' , fontSize : 14}}>Remember for 30 days</Typography>
                        </Box>
                    <LoadingButton loading={loading} disabled={loading} fullWidth variant="contained" color="primary" onClick={(e) => handleOnSubmit(e)}> Sign in</LoadingButton>
                </Box>
            </Box>
            </Box>
        <Alert {...alert_props} />
    </Box>
  );
}

export default Login;
