
/*
 * Date: 2024
 * Description: Reusable table remastered
 * Author: Philippe Leroux @ Skitsc
 */


//Modules
import { ReactElement } from "react";
import { Box } from "@mui/material";

//Interfaces
import { i_table } from "../../interfaces/utility.interface";

//Components
import HeaderV2 from "./header.v2";
import UserRow from "../settings/users/table/users.rows";
import TablePaginationV2 from "./pagination";
import LogsRow from "../settings/logs/table/logs.rows";
import UserPunchRow from "../settings/users/table/user.punch.rows";

const Tbl = ( props : i_table ) : ReactElement => {
    return (
        <Box>
            <HeaderV2 headers={props.headers}/>
            {  props.data.map(( row : any , index : number) => (
            <Box key={index}>
                { props.row_model === 'user' &&
                    <UserRow row={row} {...props}/>
                }
                { props.row_model === 'user_punch' &&
                    <UserPunchRow row={row} {...props}/>
                }
                { props.row_model === 'logs' &&
                    <LogsRow row={row} {...props}/>
                }	
            </Box>
            ))}
            {props.pagination !== undefined &&
                <TablePaginationV2 {...props.pagination} />
            }
      </Box>
    )

}

export default Tbl;