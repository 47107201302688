/*
 * Date: 2024
 * Description: Top bar menu for extra interactions
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { useContext , useEffect, useState } from "react";
import { Box, IconButton, useTheme , Tooltip , Menu , MenuItem } from "@mui/material";
import { Link  } from "react-router-dom";

//Context
import { MainContext, ThemeContext } from "../../context/context";

//Icons
import { LightModeOutlined , DarkModeOutlined } from "@mui/icons-material";
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

//Utils
import { f_set_local_key } from "../../utils/utility";

//Interfaces && types
import { i_initial_props } from "../../interfaces/utility.interface";

const TopBar = ( props : i_initial_props ) => {
    const theme = useTheme();
    const { user } = useContext(MainContext)
    const [ anchor, setAnchor] = useState<null | HTMLElement>(null);
    const { mode , setCurrentTheme , primary_color} = useContext(ThemeContext)
    const [ mobileOpen , setMobileOpen ] = useState(false);
    const handleMode = () => {
        mode === 'Light'? setCurrentTheme('Dark') : setCurrentTheme('Light')
        mode === 'Light'? f_set_local_key('theme_mode','Dark' , 2592000000) : f_set_local_key('theme_mode','Light' , 2592000000)
    } 
    const sizing = {
        width : '3vh',
        height : '3vh',
    }

    const handleMenu = ( event: React.MouseEvent<HTMLButtonElement> ) => {
        setAnchor(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchor(null);
    };
    const open = Boolean(anchor);
    useEffect(() => {
        if(!props.mobile && mobileOpen) setMobileOpen(false)
    },[props.mobile , mobileOpen])
  return (
    <Box>

        <Box sx={{ display : 'flex'  , borderBottom: "1px solid #EAECF0" ,  height : '5vh' , paddingRight : '10px' , paddingTop : '2.5px' , paddingLeft : '10px'}} >
            <IconButton component={Link} to={'/overview'} sx={{ marginRight : '1vh'}}>
                <HomeIcon sx={sizing}  color="primary"/>
            </IconButton>
            {user.type === 'Admin' && 
                <>
                    <IconButton  component={Link} to={'/settings/users'}>
                        <GroupIcon sx={sizing} color="primary"/>
                    </IconButton>
                    <IconButton component={Link} to={'/timesheets'}>
                        <LibraryBooksIcon sx={sizing} color="primary"/>
                    </IconButton>
                </>    
            }
            {user.type === 'User' && !props.mobile &&
                <Box sx={{ alignSelf : 'center' , display : 'flex'}}><Box sx={{ marginRight : '8px'}}>Welcome back</Box> <Box sx={{ fontWeight : 800}}>{user.first_name + " " + user.last_name}</Box></Box>
            }
            <Box sx={{ marginLeft : 'auto'}}>
                <Tooltip title={theme.palette.mode + " Mode"}>
                    <IconButton  color='primary' sx={{ marginRight : '1vh'}} onClick={ () => handleMode() }>
                    {mode === 'Dark' ? (
                        <DarkModeOutlined sx={[{ color : primary_color} , sizing ]}/>
                    ) : (
                        <LightModeOutlined color="primary" sx={sizing} />
                    )}
                    </IconButton>
                </Tooltip>
                <Tooltip title="Notifications">
                    <IconButton sx={[sizing , { marginRight : '1vh'}]} color='primary'>
                        <NotificationsIcon  sx={sizing}color={'primary'}/>
                    </IconButton>
                </Tooltip>
                <IconButton id="_setting_icon" color='primary' aria-controls={open ? 'setting-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}onClick={handleMenu} >             
                    <SettingsIcon sx={sizing}/>
                </IconButton>
                <Menu id="_menu"anchorEl={anchor} open={Boolean(anchor)}onClose={handleCloseMenu}MenuListProps={{'aria-labelledby': '_setting_icon'}} >
           
                    <MenuItem component={Link} onClick={handleCloseMenu} to={'/profile'}>Profile</MenuItem>
                    {user.type === 'Admin' && <MenuItem onClick={handleCloseMenu} component={Link} to={'/settings/app.config'}>App settings</MenuItem> }
                    {user.type === 'Admin' && <MenuItem onClick={handleCloseMenu} component={Link} to={'/settings/mail.config'}>Mail settings</MenuItem> }
                    {user.type === 'Admin' && <MenuItem onClick={handleCloseMenu} component={Link} to={'/settings/logs'}>Logs</MenuItem> }
                    <MenuItem component={Link} to={'/logout'}>Logout</MenuItem>
                </Menu>
            </Box>
        </Box>
    </Box>
  );
};

export default TopBar;

