/*
 * Date: 2024
 * Description: Basic modal body for forms with title and arrow exit..
 * Author: Philippe Leroux @ Skitsc
 */

import { Box , IconButton, Typography } from "@mui/material";
import { forwardRef } from "react";
import { ArrowBack } from "@mui/icons-material";

//Interfaces && types
import { s_format_modal_style } from "../../styles/modal";
import { i_shell_modal } from "../../interfaces/utility.interface";


  const ShellModal = forwardRef((props : i_shell_modal, ref) => {
    const size = props.display === 'disable' ?  'sm' :'md' 
    return (
        <Box sx={s_format_modal_style(props.mode , size)}>
            {props.display !== 'disable' &&
                <Box sx={{ display : 'flex' , marginBottom : '2vh'}}>
                    <IconButton onClick={() => props.handleClose(false)} sx={{ marginRight : '8px'}}><ArrowBack/></IconButton>
                    <Typography  sx={{ fontSize : 20 , fontWeight : 600 , alignSelf : 'center'}}>{props.title}</Typography>
                </Box>
            }
            {props.children}
        </Box>
    )})

export default ShellModal