
/*
 * Date: 2024
 * Description: Form content for mail config
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { ChangeEvent, ReactElement  } from "react";
import { Box , Grid, TextField , Typography , Switch, Tooltip, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";

//Interfaces && types
import { i_mail_config_form } from "../../../../interfaces/setting.interface";
//Styles
import { sub_title } from "../../../../styles/form.styles";
import { input_base } from "../../../../styles/main.styles";

//Const

//Icons
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

const MailInputs = ( props : i_mail_config_form ) : ReactElement => {
    const F = props.form
    const E = props.errors
    const handleChange = ( event : ChangeEvent<HTMLInputElement> ) => {
        const { name, value } = event.target;
        props.setForm({...F, [name] : value })
    }
    const handleChangeChecker = ( event : ChangeEvent<HTMLInputElement> ) => {
        const { name } = event.target;
        var value : boolean = true;
        if(name === 'pool') value =!F.pool;
        if(name === 'secure') value =!F.secure;
        if(name === 'active') value =!F.active;
        props.setForm({...F, [name] : value })
    }
    return ( 
    <Box component={"form"} p={2} m={2} sx={{ marginBottom : '2vh' , maxWidth : '900px' }} id={'app_config_inputs'} >
         <Grid container>
            <Grid item xs={12} sx={{ display : 'flex'}}>
                <Typography sx={{ fontWeight : 800, fontSize : 20, marginBottom : '2vh' , alignSelf : 'center'}}>Mail configuration</Typography>
                <Tooltip title={"Revert back to inital values"}>
                    <IconButton onClick={() => props.reset()} sx={{ marginLeft : 'auto'}}> <SettingsBackupRestoreIcon/> </IconButton>
                </Tooltip>
            </Grid>
             <Grid item xs={3}>
                <Typography sx={sub_title}>Sender address  *</Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField sx={[input_base , { width : '540px'} ]} name={'from'} size="small" placeholder={"sender.."} value={F.from} error={E.from !== ""} helperText={E.from}
                onChange={handleChange}autoComplete={props.dev ? "off" : "new-password" }/>           
            </Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Host name  *</Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField sx={[input_base , { width : '540px'} ]} name={'host'} size="small" placeholder={"mail host name.."} value={F.host} error={E.host !== ""} helperText={E.host}
                onChange={handleChange}autoComplete={props.dev ? "off" : "new-password" }/>           
            </Grid>

            <Grid item xs={3}>
                <Typography sx={sub_title}>Username * </Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField type={'text'} sx={[input_base , { width : '540px'} ]} name={'username'} placeholder={"mail username"} size="small" value={F.username} error={E.username !== ''} helperText={E.username}
                onChange={handleChange}autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Password *</Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField type={'text'} sx={[input_base , { width : '540px'} ]} name={'password'} size="small" value={F.password} error={E.password!== ''} helperText={E.password}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            <Grid item xs={3} sx={{marginBottom : '2vh'}}>
                <Typography sx={sub_title}>Pool / Secure </Typography>
            </Grid>
                <Grid item xs={9}>
                    <Tooltip title={"Use this if the connection from the main box have to be kept open"}>
                        <Switch sx={{ marginLeft : '2vh' , marginRight : '2vh' }} size="medium" checked={F.pool} name={'pool'} onChange={handleChangeChecker}></Switch>
                    </Tooltip>
                    <Tooltip title={"Port 465 on secure and 587 on not secured"}>
                        <Switch checked={F.secure} name={'secure'} onChange={handleChangeChecker}></Switch>
                    </Tooltip>
                </Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Cron job </Typography>
            </Grid>
            <Grid item xs={9}>
                <Tooltip title={"Cron jobs for mails , send's an email to remind to punchout"}>
                    <Switch  sx={{ marginLeft : '2vh' , marginRight : '2vh'}} checked={F.active} name={'active'} onChange={handleChangeChecker}></Switch>
                </Tooltip>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={9} mt={2}>
                <LoadingButton sx={{ display : 'block', border : '1px solid gray' , maxWidth : '540px'}} loading={false} fullWidth variant="contained" color="primary" onClick={() =>props.callback()}>Save changes </LoadingButton>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={9} mt={6}>
                <LoadingButton sx={{ display : 'block', border : '1px solid gray' , maxWidth : '540px'}} loading={false} fullWidth variant="contained" color="primary" onClick={() =>props.tester()}>Test configuration</LoadingButton>
            </Grid>
            <Grid item xs={3}></Grid>
        </Grid>
        </Box>
    
    )
}

export default MailInputs;