

/*
 * Date: 2023
 * Description: Middlewares that are reusables.
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import dayjs from "dayjs";

//Constants
import { allowed_extensions ,  lexique, lexique_message, users_type } from "../utils/constant";
import { f_return_color } from "../utils/utility";

//Interfaces && types
import { t_lang} from "../types/types";
import { t_punch_type } from "../interfaces/timesheet.interface";


const max_allowed_size = 1024 * 1024
const m_picture_validation = (file: File, validationType: 'allowed' | 'size'): boolean => {
    const extension = file.name.split('.').pop()?.toLowerCase();
    switch (validationType) {
        case 'allowed':
            return !!extension && allowed_extensions.includes(extension);
        case 'size':
            return !!extension && file.size <= max_allowed_size;
        default:
            return false
    }
};
const m_validate_punch_type = ( type : string ) : boolean => {
    return ['in','lunch','break'].includes(type as t_punch_type)
}
const m_validate_number = (value: any): boolean => {
    if (value === null || value === undefined) return false;
    if (!isNaN(Number(value))) return true;
    return false;
}
const m_validate_email = ( email : string ) : boolean => {
    return lexique.email_regex.test(email)
}
const m_validate_email_opt = ( email : string ) : boolean => {
    if(email !== '') return lexique.email_regex.test(email)
    return true;
}
const m_validate_hex_colors = ( color : string ) : boolean => {
    return lexique.hexColorRegex.test(color)
}
const m_validate_password = ( password : string ) : boolean => {
    return lexique.password_regex.test(password)
}
const m_validate_password_opt = ( password : string ) : boolean => {
    if(password !== '') return lexique.password_regex.test(password)
    return true
}
const m_validate_str = ( str : string ) : boolean => {
    return lexique.str_regex.test(str)
}
const m_validate_phone = ( phone : string ) : boolean => {
    return lexique.phone_regex.test(phone)
}
const m_validate_opt_phone = ( opt_phone : string ) : boolean => {
    if(opt_phone.length === 0) return true
    const res = m_validate_phone(opt_phone)
    return res
}
const m_validate_opt_zip_code = ( opt_zip : string ) : boolean => {
    if(opt_zip.length === 0) return true
    const res = m_validate_zip_code(opt_zip)
    return res
}
const m_validate_zip_code = ( zip_code : string ) : boolean => {
    return lexique.zip_regex.test(zip_code)
}
const m_validate_empty_fields = ( field : string ) : boolean => {
    if(field.length === 0) return false
    return true
}
const m_force_str = ( value : string ) : string => {
    var valid : boolean = true;
    valid = m_validate_empty_fields(value)
    if(!valid) return lexique_message.empty_error
    valid = m_validate_str(value)
    if(!valid) return lexique_message.str_error
    return ''
}
const m_validate_url = ( url : string ) : boolean => {
    return lexique.urlRegex.test(url)
}
const m_validate_url_opt = ( url : string ) : boolean => {
    if(url !== '') return lexique.urlRegex.test(url)
    return true
}
const m_validate_empty_fields_nullable = ( field : string | null) : boolean => {
    if(field !== null && field.length === 0) return false
    return true
}
const m_validate_string_arr = (input: any): input is string[] =>{
    if (!Array.isArray(input)) return false; 
    for (const item of input) {
      if (typeof item !== 'string')  return false;
    }
    return true;
  }
  const m_validate_file_arr = (input: any): input is File[] =>{
    if (!Array.isArray(input)) return false; 
    for (const item of input) {
      if (!(item instanceof File))  return false;
    }
    return true;
  }
  const m_validate_date = ( value: any ) : boolean => {
    // Check if the value is a JavaScript Date or a dayjs object
    if(dayjs.isDayjs(value)) {
        return dayjs(value).isValid();
    }
    return false;
  }
  
  const m_validate_user_type = ( value : any ) : boolean => {
    return users_type.some(item => item.value === value);
  }
  const m_validate_colors = ( input : any ) : boolean => {
    var res = f_return_color(input)
    if(res === '#000000') return false
    return true
  }

const m_validate_select = ( value : string ) : boolean => {
    if(value.length === 0 || value === 'Default' || value === 'default') return false
    return true
}

const m_validate_language = (value: string) : boolean => {
    return ["FR","EN"].includes(value as t_lang);
}

const m_validate_opt_nas = ( value : string ) : boolean => {
    if(value.length === 0) return true
    return lexique.nas_regex.test(value)
}
const m_validate_numeric_str = (value: any): boolean => {
    return lexique.nip_regex.test(value);
}

export { m_picture_validation , m_validate_password_opt, m_validate_numeric_str,  m_validate_opt_nas, m_validate_select , m_validate_number, m_validate_email, m_validate_password, m_validate_str, m_validate_phone , m_validate_zip_code, m_validate_opt_zip_code, m_validate_email_opt , m_validate_url_opt,
    m_validate_date , m_validate_language , m_validate_hex_colors, m_validate_punch_type,
    m_validate_empty_fields , m_validate_string_arr , m_validate_file_arr , m_validate_empty_fields_nullable , m_validate_url , m_validate_user_type , m_force_str , m_validate_opt_phone , m_validate_colors };